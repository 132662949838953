const TRANSLATIONS = {
  // Allgemein
  common: {
    "workspaces-name": "Arbeitsbereichsname",
    error: "Fehler",
    success: "Erfolg",
    user: "Benutzer",
    selection: "Modell-Auswahl",
    saving: "Speichern...",
    save: "Änderungen speichern",
    previous: "Vorherige Seite",
    next: "Nächste Seite",
  },

  // Popup Confirmation
  deleteWorkspaceConfirmation:
    "Sind Sie sicher, dass Sie {{username}} löschen möchten?\nNachdem Sie dies tun, wird der Benutzer abgemeldet und kann diese Instanz nicht mehr verwenden.\n\nDiese Aktion ist unwiderruflich.",
  deleteConfirmation:
    "Sind Sie sicher, dass Sie {{username}} löschen möchten?\nNachdem Sie dies tun, wird der Benutzer abgemeldet und kann diese Instanz nicht mehr verwenden.\n\nDiese Aktion ist unwiderruflich.",
  suspendConfirmation:
    "Sind Sie sicher, dass Sie {{username}} suspendieren möchten?\nNachdem Sie dies tun, wird der Benutzer abgemeldet und kann sich nicht erneut bei dieser Instanz anmelden, bis er von einem Administrator wieder freigeschaltet wird.",

  // Sidebar-Menüeinträge für Einstellungen
  settings: {
    title: "Instanz-Einstellungen",
    system: "Allgemeine Einstellungen",
    invites: "Einladungen",
    users: "Benutzer",
    workspaces: "Arbeitsbereiche",
    "workspace-chats": "Arbeitsbereich-Chats",
    customization: "Anpassung",
    "api-keys": "Entwickler-API",
    llm: "LLM",
    transcription: "Transkription",
    embedder: "Embedder",
    "text-splitting": "Text-Splitter & Chunking",
    "vector-database": "Vektor-Datenbank",
    embeds: "Chat-Embed",
    "embed-chats": "Chat-Embed-Verlauf",
    security: "Sicherheit",
    "event-logs": "Ereignisprotokolle",
    privacy: "Datenschutz & Daten",
    "ai-providers": "KI-Anbieter",
    "agent-skills": "Agentenfähigkeiten",
    admin: "Admin",
    tools: "Werkzeuge",
    audio: "Audio-Einstellungen",
    "link-settings": "Einstellungen",
    "default-settings": "Standardeinstellungen",
    "browser-extension": "Browser Extension",
  },

  // Qura buttons,
  qura: {
    "copy-to-cora": "Qura-Quellenprüfung",
    "qura-status": "Qura-Button ist ",
    "copy-option": "Kopieroption",
    "option-quest": "Frage",
    "option-resp": "Antwort",
    "role-description":
      "Fügen Sie eine Qura-Schaltfläche hinzu, um Antworten auf Qura.law anzufordern",
  },

  // Seiten Definitionen
  login: {
    "multi-user": {
      welcome: "Willkommen bei",
      "placeholder-username": "Benutzername",
      "placeholder-password": "Passwort",
      login: "Anmelden",
      validating: "Überprüfen...",
      "forgot-pass": "Passwort vergessen",
      reset: "Zurücksetzen",
    },
    "sign-in": {
      start: "Melden Sie sich bei Ihrem Konto an",
      end: "Konto.",
    },
    button: "Anmelden",
    password: {
      forgot: "PASSWORT VERGESSEN?",
      contact: "Bitte wenden Sie sich an den Systemadministrator.",
    },
    publicMode: "Öffentlicher Modus",
  },

  "new-workspace": {
    title: "Neuer Arbeitsbereich",
    placeholder: "Mein Arbeitsbereich",
    "legal-areas": "Rechtsgebiete",
    create: {
      title: "Neuen Arbeitsbereich erstellen",
      description:
        "Nach der Erstellung dieses Arbeitsbereichs können nur Administratoren ihn sehen. Sie können Benutzer hinzufügen, nachdem er erstellt wurde.",
      error: "Fehler: ",
      cancel: "Abbrechen",
      "create-workspace": "Arbeitsbereich erstellen",
    },
  },

  "workspace-chats": {
    welcome: "Willkommen in Ihrem neuen Arbeitsbereich.",
    "desc-start": "Um zu beginnen, entweder",
    "desc-mid": "ein Dokument hochladen",
    "desc-or": "oder",
    start: "Um zu beginnen",
    "desc-end": "einen Chat senden.",
    prompt: {
      send: "Senden",
      "send-message": "Nachricht senden",
      placeholder: "Fragen Sie nach rechtlichen Informationen",
      slash: "Alle verfügbaren Slash-Befehle für den Chat anzeigen.",
      "change-size": "Textgröße ändern",
      reset: "Reset your chat",
      clear: "Chatverlauf löschen und einen neuen Chat beginnen",
      "new-preset": "Neues Preset hinzufügen",
      command: "Befehl",
      description: "Beschreibung",
      save: "speichern",
      small: "Klein",
      normal: "Normal",
      large: "Groß",
      attach: "Datei an diesen Chat anhängen",
      upgrade: "Aktualisiere deinen Prompt",
      upgrading: "Aktualisiert deinen Prompt",
      "original-prompt": "Ursprünglicher Prompt:",
      "upgraded-prompt": "Aktualisierter Prompt:",
      "edit-prompt":
        "Sie können den neuen Prompt bearbeiten, bevor Sie ihn absenden.",
    },
  },

  header: {
    account: "Konto",
    login: "Anmelden",
    "sign-out": "Abmelden",
  },

  workspace: {
    title: "Instanz-Arbeitsbereiche",
    description:
      "Dies sind alle Arbeitsbereiche, die in dieser Instanz existieren. Das Entfernen eines Arbeitsbereichs löscht alle damit verbundenen Chats und Einstellungen.",
    "new-workspace": "Neuer Arbeitsbereich",
    name: "Name",
    link: "Link",
    users: "Benutzer",
    "created-on": "Erstellt Am",
    save: "Änderungen speichern",
    cancel: "Abbrechen",
  },

  // Arbeitsbereich-Einstellungen Menüeinträge
  "workspaces-settings": {
    general: "Allgemeine Einstellungen",
    chat: "Chat-Einstellungen",
    vector: "Vektor-Datenbank",
    members: "Mitglieder",
    agent: "Agent-Konfiguration",
    "general-settings": {
      "workspace-name": "Arbeitsbereichsname",
      "desc-name": "Dies ändert nur den Anzeigenamen Ihres Arbeitsbereichs.",
      "assistant-profile": "Profilbild des Assistenten",
      "assistant-image":
        "Passen Sie das Profilbild des Assistenten für diesen Arbeitsbereich an.",
      "workspace-image": "Arbeitsbereichsbild",
      "remove-image": "Arbeitsbereichsbild entfernen",
      delete: "Arbeitsbereich löschen",
      deleting: "Arbeitsbereich wird gelöscht...",
      update: "Arbeitsbereich aktualisieren",
      updating: "Aktualisiere Arbeitsbereich...",
    },
    "chat-settings": {
      type: "Chat-Typ",
      private: "Privat",
      standard: "Standard",
      "private-desc-start": "wird manuell den",
      "private-desc-mid": "nur",
      "private-desc-end": "bestimmten Benutzern gewährt.",
      "standard-desc-start": "wird automatisch allen",
      "standard-desc-mid": "neuen",
      "standard-desc-end": "Benutzern gewährt.",
    },
    users: {
      manage: "Benutzer verwalten",
      "workspace-member": "Keine Arbeitsbereichsmitglieder",
      username: "Benutzername",
      role: "Rolle",
      date: "Hinzugefügt Am",
      users: "Benutzer",
      search: "Suche nach einem Benutzer",
      "no-user": "Keine Benutzer gefunden",
      select: "Alle auswählen",
      unselect: "Abwählen",
      save: "Speichern",
    },
    "linked-workspaces": {
      title: "Verknüpfte Arbeitsbereiche",
      "linked-workspace": "Keine verknüpften Arbeitsbereiche",
      manage: "Arbeitsbereiche verwalten",
      name: "Name",
      slug: "Slug",
      date: "Hinzugefügt am",
      workspaces: "Arbeitsbereiche",
      search: "Arbeitsbereich suchen",
      "no-workspace": "Keine Arbeitsbereiche gefunden",
      select: "Alles auswählen",
      unselect: "Abwählen",
      save: "Speichern",
    },
  },

  // Allgemeine Erscheinung
  general: {
    vector: {
      title: "Vektoranzahl",
      description: "Gesamtanzahl der Vektoren in Ihrer Vektor-Datenbank.",
      vectors: "Anzahl der Vektoren",
    },
    names: {
      description: "Dies ändert nur den Anzeigenamen Ihres Arbeitsbereichs.",
    },
    message: {
      title: "Vorgeschlagene Chat-Nachrichten",
      description:
        "Passen Sie die Nachrichten an, die Ihren Arbeitsbereichsbenutzern vorgeschlagen werden.",
      add: "Neue Nachricht hinzufügen",
      save: "Nachrichten speichern",
      heading: "Erklären Sie mir",
      body: "die Vorteile der Plattform",
      message: "Nachricht",
      "new-heading": "Überschrift",
    },
    pfp: {
      title: "Profilbild des Assistenten",
      description:
        "Passen Sie das Profilbild des Assistenten für diesen Arbeitsbereich an.",
      image: "Arbeitsbereichsbild",
      remove: "Arbeitsbereichsbild entfernen",
    },
    delete: {
      delete: "Arbeitsbereich löschen",
      deleting: "Arbeitsbereich wird gelöscht...",
      "confirm-start": "Sie sind dabei, Ihren gesamten",
      "confirm-end":
        "Arbeitsbereich zu löschen. Dies entfernt alle Vektor-Einbettungen in Ihrer Vektor-Datenbank.\n\nDie ursprünglichen Quelldateien bleiben unberührt. Diese Aktion ist nicht umkehrbar.",
    },
  },

  // Chat-Einstellungen
  chat: {
    llm: {
      title: "Arbeitsbereich LLM-Anbieter",
      description:
        "Der spezifische LLM-Anbieter & Modell, das für diesen Arbeitsbereich verwendet wird. Standardmäßig werden die System-LLM-Einstellungen verwendet.",
      search: "Alle LLM-Anbieter durchsuchen",
    },
    model: {
      title: "Arbeitsbereich Chat-Modell",
      description:
        "Das spezifische Chat-Modell, das für diesen Arbeitsbereich verwendet wird. Wenn leer, werden die Systemeinstellungen für LLM verwendet.",
      wait: "-- auf Modelle warten --",
    },
    mode: {
      title: "Chat-Modus",
      chat: {
        title: "Chat",
        "desc-start":
          "stellt Antworten mit dem allgemeinen Wissen des LLM bereit",
        and: "und",
        "desc-end": "Dokumentkontext, der gefunden wird.",
      },
      query: {
        title: "Abfrage",
        "desc-start": "stellt Antworten bereit",
        only: "nur",
        "desc-end": "wenn Dokumentkontext gefunden wird.",
      },
    },
    history: {
      title: "Chat-Verlauf",
      "desc-start":
        "Die Anzahl der vorherigen Chats, die im Kurzzeitgedächtnis der Antwort enthalten sind.",
      recommend: "Empfohlene Anzahl: 20",
      "desc-end":
        "Alles, was mehr als 45 beträgt, kann je nach Nachrichtengröße zu kontinuierlichen Chat-Fehlern führen.",
    },
    prompt: {
      title: "Eingabeaufforderung",
      description:
        "Die Eingabeaufforderung, die in diesem Arbeitsbereich verwendet wird. Definieren Sie den Kontext und die Anweisungen für die KI, um eine Antwort zu generieren. Sie sollten eine sorgfältig ausgearbeitete Eingabeaufforderung bereitstellen, damit die KI eine relevante und genaue Antwort generieren kann.",
    },
    refusal: {
      title: "Abfrage-Modus Ablehnungsantwort",
      "desc-start": "Im",
      query: "Abfrage",
      "desc-end":
        "Modus möchten Sie möglicherweise eine benutzerdefinierte Ablehnungsantwort zurückgeben, wenn kein Kontext gefunden wird.",
    },
    temperature: {
      title: "LLM-Temperatur",
      "desc-start":
        'Diese Einstellung steuert, wie "kreativ" die Antworten Ihres LLM sein werden.',
      "desc-end":
        "Je höher die Zahl, desto kreativer. Bei einigen Modellen kann dies zu inkohärenten Antworten führen, wenn der Wert zu hoch eingestellt ist.",
      hint: "Die meisten LLMs haben verschiedene akzeptable Wertebereiche. Konsultieren Sie Ihren LLM-Anbieter für diese Informationen.",
    },
  },

  // Vektor-Datenbank
  "vector-workspace": {
    identifier: "Vektor-Datenbank-Identifier",
    snippets: {
      title: "Maximale Kontext-Snippets",
      description:
        "Diese Einstellung steuert die maximale Anzahl von Kontext-Snippets, die pro Chat oder Abfrage an das LLM gesendet werden.",
      recommend: "Empfohlen: 4",
    },
    doc: {
      title: "Dokumentähnlichkeitsschwelle",
      description:
        "Der Mindestähnlichkeitswert, der erforderlich ist, damit eine Quelle als relevant für den Chat angesehen wird. Je höher der Wert, desto ähnlicher muss die Quelle zum Chat sein.",
      zero: "Keine Einschränkung",
      low: "Niedrig (Ähnlichkeitswert ≥ .25)",
      medium: "Mittel (Ähnlichkeitswert ≥ .50)",
      high: "Hoch (Ähnlichkeitswert ≥ .75)",
    },
    reset: {
      reset: "Vektor-Datenbank zurücksetzen",
      resetting: "Vektoren werden gelöscht...",
      confirm:
        "Sie sind dabei, die Vektor-Datenbank dieses Arbeitsbereichs zurückzusetzen. Dies entfernt alle aktuell eingebetteten Vektor-Einbettungen.\n\nDie ursprünglichen Quelldateien bleiben unberührt. Diese Aktion ist irreversibel.",
      error:
        "Die Vektor-Datenbank des Arbeitsbereichs konnte nicht zurückgesetzt werden!",
      success: "Die Vektor-Datenbank des Arbeitsbereichs wurde zurückgesetzt!",
    },
  },

  // Agent-Konfiguration
  agent: {
    "performance-warning":
      "Die Leistung von LLMs, die keine explizite Unterstützung für Tool-Aufrufe bieten, hängt stark von den Fähigkeiten und der Genauigkeit des Modells ab. Einige Fähigkeiten können eingeschränkt oder nicht funktionsfähig sein.",
    provider: {
      title: "Arbeitsbereich Agent LLM-Anbieter",
      description:
        "Der spezifische LLM-Anbieter & Modell, das für den @agent-Agenten dieses Arbeitsbereichs verwendet wird.",
    },
    mode: {
      chat: {
        title: "Arbeitsbereich Agent Chat-Modell",
        description:
          "Das spezifische Chat-Modell, das für den @agent-Agenten dieses Arbeitsbereichs verwendet wird.",
      },
      title: "Arbeitsbereich Agent Modell",
      description:
        "Das spezifische LLM-Modell, das für den @agent-Agenten dieses Arbeitsbereichs verwendet wird.",
      wait: "-- auf Modelle warten --",
    },

    skill: {
      title: "Standard-Agent-Fähigkeiten",
      description:
        "Verbessern Sie die natürlichen Fähigkeiten des Standard-Agenten mit diesen vorgefertigten Fähigkeiten. Diese Konfiguration gilt für alle Arbeitsbereiche.",
      rag: {
        title: "RAG & Langzeitspeicher",
        description:
          'Ermöglichen Sie dem Agenten, Ihre lokalen Dokumente zu nutzen, um eine Abfrage zu beantworten oder den Agenten zu bitten, Stücke von Inhalten für die Langzeitspeicherung "zu merken".',
      },
      configure: {
        title: "Agentenfähigkeiten konfigurieren",
        description:
          "Passen Sie die Fähigkeiten des Standard-Agenten an, indem Sie bestimmte Fähigkeiten aktivieren oder deaktivieren. Diese Einstellungen werden in allen Arbeitsbereichen angewendet.",
      },
      view: {
        title: "Dokumente anzeigen & zusammenfassen",
        description:
          "Ermöglichen Sie dem Agenten, den Inhalt der derzeit eingebetteten Arbeitsbereichsdateien aufzulisten und zusammenzufassen.",
      },
      scrape: {
        title: "Websites durchsuchen",
        description:
          "Ermöglichen Sie dem Agenten, Websites zu besuchen und deren Inhalte zu durchsuchen.",
      },
      generate: {
        title: "Diagramme erstellen",
        description:
          "Aktivieren Sie den Standard-Agenten, um verschiedene Arten von Diagrammen aus den bereitgestellten Daten oder im Chat zu erstellen.",
      },
      save: {
        title: "Dateien generieren & im Browser speichern",
        description:
          "Ermöglichen Sie dem Standard-Agenten, Dateien zu generieren und zu schreiben, die gespeichert und in Ihrem Browser heruntergeladen werden können.",
      },
      web: {
        title: "Live-Websuche und -Durchsuchen",
        "desc-start":
          "Ermöglichen Sie Ihrem Agenten, das Web zu durchsuchen, um Ihre Fragen zu beantworten, indem Sie eine Web-Such (SERP) -Anbieter verbinden.",
        "desc-end":
          "Die Websuche während Agentensitzungen funktioniert nicht, bis dies eingerichtet ist.",
      },
    },
  },

  // Arbeitsbereich-Chats
  recorded: {
    title: "Arbeitsbereich-Chats",
    description:
      "Dies sind alle aufgezeichneten Chats und Nachrichten, die von Benutzern gesendet wurden, sortiert nach ihrem Erstellungsdatum.",
    export: "Exportieren",
    table: {
      id: "Id",
      by: "Gesendet Von",
      workspace: "Arbeitsbereich",
      prompt: "Eingabeaufforderung",
      response: "Antwort",
      at: "Gesendet Am",
    },
    "clear-chats": "Chats löschen",
  },

  // Erscheinungsbild
  appearance: {
    title: "Erscheinungsbild",
    description: "Passen Sie die Erscheinungseinstellungen Ihrer Plattform an.",
    logo: {
      title: "Logo anpassen",
      description:
        "Laden Sie Ihr benutzerdefiniertes Logo für den Hellmodus hoch.",
      add: "Benutzerdefiniertes Logo hinzufügen",
      recommended: "Empfohlene Größe: 800 x 200",
      remove: "Entfernen",
      replace: "Ersetzen",
    },
    logoDark: {
      title: "Logo für den Dunkelmodus anpassen",
      description:
        "Laden Sie Ihr benutzerdefiniertes Logo für den Dunkelmodus hoch.",
      add: "Benutzerdefiniertes Logo hinzufügen",
      recommended: "Empfohlene Größe: 800 x 200",
      remove: "Entfernen",
      replace: "Ersetzen",
    },
    message: {
      title: "Nachrichten anpassen",
      description:
        "Passen Sie die automatischen Nachrichten an, die Ihren Benutzern angezeigt werden.",
      new: "Neu",
      system: "System",
      user: "Benutzer",
      message: "Nachricht",
      assistant: "Chat-Assistent",
      "double-click": "Doppelklicken, um zu bearbeiten...",
      save: "Nachrichten speichern",
    },
    icons: {
      title: "Benutzerdefinierte Fußzeilen-Icons",
      description:
        "Passen Sie die Fußzeilen-Icons an, die auf Ihrer Plattform angezeigt werden.",
      icon: "Symbol",
      link: "Link",
    },
    display: {
      title: "Anzeigesprache",
      description: "Wählen Sie die bevorzugte Sprache.",
    },
    color: {
      title: "Benutzerdefinierte Farben",
      "desc-start": "Passen Sie die",
      "desc-mid": "*Hintergrundfarbe, *Primärfarbe",
      "desc-and": "und",
      "desc-end": "*Textfarbe an",
      red: "Rot",
    },
    login: {
      title: "Benutzerdefinierter Anmeldetext",
      description:
        "Passen Sie den Absatztext an, der auf der Anmeldeseite angezeigt wird.",
      save: "speichern",
    },
    siteSettings: {
      title: "Benutzerdefinierte Website-Einstellungen",
      description:
        "Ändern Sie den Inhalt der Browser-Registerkarte für Anpassung und Branding.",
      tabTitle: "Registerkarten-Titel",
      tabDescription:
        "Legen Sie einen benutzerdefinierten Registerkarten-Titel fest, wenn die App in einem Browser geöffnet ist.",
      tabIcon: "Tab-Favicon",
      fabIconUrl:
        "Definieren Sie eine URL zu einem Bild, das für Ihr Favicon verwendet werden soll",
    },
  },

  api: {
    title: "API-Schlüssel",
    description:
      "API-Schlüssel ermöglichen es dem Inhaber, programmgesteuert auf diese instanz zuzugreifen und sie zu verwalten.",
    link: "Lesen Sie die API-Dokumentation",
    generate: "Neuen API-Schlüssel erstellen",
    table: {
      key: "API-Schlüssel",
      by: "Erstellt von",
      created: "Erstellt",
    },
    new: {
      title: "Neuen API-Schlüssel erstellen",
      description:
        "Nach der Erstellung kann der API-Schlüssel verwendet werden, um programmgesteuert auf diese instanz zuzugreifen und sie zu konfigurieren.",
      doc: "Lesen Sie die API-Dokumentation",
      cancel: "Abbrechen",
      "create-api": "API-Schlüssel erstellen",
    },
  },

  llm: {
    title: "LLM-Präferenz",
    description:
      "Dies sind die Anmeldedaten und Einstellungen für Ihren bevorzugten LLM-Chat- und Einbettungsanbieter. Es ist wichtig, dass diese Schlüssel aktuell und korrekt sind, da das System sonst nicht ordnungsgemäß funktioniert.",
    provider: "LLM-Anbieter",
    "none-selected": "Keiner ausgewählt",
    "select-llm": "Sie müssen ein LLM auswählen",
    "search-llm": "Alle LLM-Anbieter durchsuchen",
  },

  "llm-provider": {
    openai:
      "Die Standardoption für die meisten nicht-kommerziellen Anwendungen.",
    azure: "Die Unternehmensoption von OpenAI, gehostet auf Azure-Diensten.",
    anthropic: "Ein freundlicher KI-Assistent, gehostet von Anthropic.",
    gemini: "Googles größtes und leistungsfähigstes KI-Modell.",
    huggingface:
      "Zugriff auf 150.000+ Open-Source-LLMs und die weltweite KI-Community.",
    ollama: "LLMs lokal auf Ihrem eigenen Rechner ausführen.",
    lmstudio:
      "Entdecken, herunterladen und ausführen Tausender cutting-edge LLMs mit wenigen Klicks.",
    localai: "LLMs lokal auf Ihrem eigenen Rechner ausführen.",
    togetherai: "Open-Source-Modelle von Together AI ausführen.",
    mistral: "Open-Source-Modelle von Mistral AI ausführen.",
    perplexityai:
      "Leistungsstarke und internetverbundene Modelle von Perplexity AI ausführen.",
    openrouter: "Eine einheitliche Schnittstelle für LLMs.",
    groq: "Die schnellste LLM-Inferenz für Echtzeit-KI-Anwendungen.",
    koboldcpp: "Lokale LLMs mit koboldcpp ausführen.",
    oobabooga: "Lokale LLMs mit Oobaboogas Textgenerierungs-Web-UI ausführen.",
    cohere: "Cohere's leistungsstarke Command-Modelle ausführen.",
    lite: "LiteLLM's OpenAI-kompatiblen Proxy für verschiedene LLMs verwenden.",
    "generic-openai":
      "Verbinden Sie sich mit jedem OpenAi-kompatiblen Service über eine benutzerdefinierte Konfiguration.",
    native:
      "Verwenden Sie ein heruntergeladenes benutzerdefiniertes Llama-Modell zum Chatten in dieser instanz.",
  },

  // audio preference
  audio: {
    title: "Spracherkennungseinstellungen",
    provider: "Anbieter",
    "desc-speech":
      "Hier können Sie angeben, welche Art von Text-zu-Sprache- und Sprache-zu-Text-Anbietern Sie in Ihrem Plattform-Erlebnis verwenden möchten. Standardmäßig verwenden wir die im Browser integrierte Unterstützung für diese Dienste, aber Sie möchten möglicherweise andere verwenden.",
    "title-text": "Text-zu-Sprache-Einstellungen",
    "desc-text":
      "Hier können Sie angeben, welche Art von Text-zu-Sprache-Anbietern Sie in Ihrem Plattform-Erlebnis verwenden möchten. Standardmäßig verwenden wir die im Browser integrierte Unterstützung für diese Dienste, aber Sie können auch andere verwenden.",
    "desc-config": "Für diesen Anbieter ist keine Konfiguration erforderlich.",
    "placeholder-stt": "Sprache-zu-Text-Anbieter suchen",
    "placeholder-tts": "Text-zu-Sprache-Anbieter suchen",
    "native-stt":
      "Verwendet den im Browser integrierten STT-Dienst, falls unterstützt.",
    "native-tts":
      "Verwendet den im Browser integrierten TTS-Dienst, falls unterstützt.",
    openai: "Verwenden Sie die Text-zu-Sprache-Stimmen von OpenAI.",
    elevenlabs:
      "Verwenden Sie die Text-zu-Sprache-Stimmen und Technologie von ElevenLabs.",
  },

  transcription: {
    title: "Transkriptionsmodell-Einstellungen",
    description:
      "Dies sind die Anmeldedaten und Einstellungen für Ihren bevorzugten Transkriptionsmodellanbieter. Es ist wichtig, dass diese Schlüssel aktuell und korrekt sind, da sonst Medien- und Audiodateien nicht transkribiert werden.",
    provider: "Transkriptionsanbieter",
    "warn-start":
      "Die Verwendung des lokalen Whisper-Modells auf Rechnern mit begrenztem RAM oder CPU kann die Plattform beim Verarbeiten von Mediendateien zum Stillstand bringen.",
    "warn-recommend":
      "Wir empfehlen mindestens 2 GB RAM und das Hochladen von Dateien <10 MB.",
    "warn-end":
      "Das integrierte Modell wird automatisch beim ersten Gebrauch heruntergeladen.",
    "search-audio": "Audio-Transkriptionsanbieter suchen",
  },

  embedding: {
    title: "Einbettungs-Einstellungen",
    "desc-start":
      "Wenn Sie ein LLM verwenden, das keine native Unterstützung für ein Einbettungs-Engine bietet, müssen Sie möglicherweise zusätzlich Anmeldedaten für das Einbetten von Text angeben.",
    "desc-end":
      "Das Einbetten ist der Prozess der Umwandlung von Text in Vektoren. Diese Anmeldedaten sind erforderlich, um Ihre Dateien und Eingabeaufforderungen in ein Format zu konvertieren, das unsere Plattform verwenden kann.",
    provider: {
      title: "Einbettungsanbieter",
      description:
        "Für die Verwendung des nativen Einbettungs-Engines von die Plattform ist keine Einrichtung erforderlich.",
      "search-embed": "Alle Einbettungsanbieter durchsuchen",
    },
  },

  text: {
    title: "Texteinschneide- und Chunking-Einstellungen",
    "desc-start":
      "Manchmal möchten Sie möglicherweise die Standardmethode ändern, wie neue Dokumente aufgeteilt und gechunkt werden, bevor sie in Ihre Vektordatenbank eingefügt werden.",
    "desc-end":
      "Sie sollten diese Einstellung nur ändern, wenn Sie verstehen, wie das Texteinschneiden funktioniert und welche Nebenwirkungen es haben kann.",
    "warn-start": "Änderungen hier gelten nur für",
    "warn-center": "neu eingebettete Dokumente",
    "warn-end": ", nicht für vorhandene Dokumente.",
    size: {
      title: "Text Chunk Größe",
      description:
        "Dies ist die maximale Anzahl von Zeichen, die in einem einzelnen Vektor vorhanden sein können.",
      recommend: "Maximale Einbettungsmodell-Länge ist",
    },

    overlap: {
      title: "Text Chunk Überlappung",
      description:
        "Dies ist die maximale Überlappung von Zeichen, die während des Chunkings zwischen zwei benachbarten Text-Chunks auftritt.",
    },
  },

  // Vector Database
  vector: {
    title: "Vektordatenbank",
    description:
      "Dies sind die Anmeldedaten und Einstellungen, wie Ihre instanz funktionieren wird. Es ist wichtig, dass diese Schlüssel aktuell und korrekt sind.",
    provider: {
      title: "Vektordatenbank-Anbieter",
      description: "Für LanceDB ist keine Konfiguration erforderlich.",
      "search-db": "Alle Vektordatenbankanbieter durchsuchen",
    },
  },

  // Embeddable Chat Widgets
  embeddable: {
    title: "Einbettbare Chat-Widgets",
    description:
      "Einbettbare Chat-Widgets sind öffentlich zugängliche Chat-Oberflächen, die an einen einzelnen Arbeitsbereich gebunden sind. Diese ermöglichen es Ihnen, Arbeitsbereiche zu erstellen, die Sie dann der Welt veröffentlichen können.",
    create: "Embed erstellen",
    table: {
      workspace: "Arbeitsbereich",
      chats: "Gesendete Chats",
      Active: "Aktive Domains",
    },
  },

  "embed-chats": {
    title: "Chats einbetten",
    export: "Export",
    description:
      "Dies sind alle aufgezeichneten Chats und Nachrichten aus jedem Embed, das Sie veröffentlicht haben.",
    table: {
      embed: "Embed",
      sender: "Absender",
      message: "Nachricht",
      response: "Antwort",
      at: "Gesendet am",
    },
  },

  multi: {
    title: "Mehrbenutzermodus",
    description:
      "Richten Sie Ihre Instanz so ein, dass sie Ihr Team unterstützt, indem Sie den Mehrbenutzermodus aktivieren.",
    enable: {
      "is-enable": "Mehrbenutzermodus ist aktiviert",
      enable: "Mehrbenutzermodus aktivieren",
      description:
        "Standardmäßig sind Sie der einzige Administrator. Als Administrator müssen Sie Konten für alle neuen Benutzer oder Administratoren erstellen. Verlieren Sie Ihr Passwort nicht, da nur ein Administrator Passwörter zurücksetzen kann.",
      username: "Administrator-Benutzername",
      password: "Administrator-Passwort",
    },
    password: {
      title: "Passwortschutz",
      description:
        "Schützen Sie Ihre instanz mit einem Passwort. Wenn Sie dies vergessen, gibt es keine Wiederherstellungsmethode, also stellen Sie sicher, dass Sie dieses Passwort speichern.",
    },
    instance: {
      title: "Passwortschutz für Instanz",
      description:
        "Standardmäßig sind Sie der einzige Administrator. Als Administrator müssen Sie Konten für alle neuen Benutzer oder Administratoren erstellen. Verlieren Sie Ihr Passwort nicht, da nur ein Administrator Passwörter zurücksetzen kann.",
      password: "Instanz-Passwort",
    },
  },

  event: {
    title: "Ereignisprotokolle",
    description:
      "Zeigen Sie alle Aktionen und Ereignisse an, die auf dieser Instanz für die Überwachung stattfinden.",
    clear: "Ereignisprotokolle löschen",
    table: {
      type: "Ereignistyp",
      user: "Benutzer",
      occurred: "Aufgetreten am",
    },
  },

  privacy: {
    title: "Datenschutz & Datenverarbeitung",
    description:
      "Dies ist Ihre Konfiguration, wie verbundene Drittanbieter und unsere Plattform Ihre Daten verarbeiten.",
    llm: "LLM-Auswahl",
    embedding: "Einbettungspräferenz",
    vector: "Vektor-Datenbank",
    anonymous: "Anonyme Telemetrie aktiviert",
    "desc-event": "Alle Ereignisse zeichnen keine IP-Adresse auf und enthalten",
    "desc-id": "keine identifizierenden",
    "desc-cont":
      "Inhalte, Einstellungen, Chats oder andere nicht nutzungsbasierte Informationen. Um die Liste der gesammelten Ereignistags zu sehen, können Sie hier auf",
    "desc-git": "Github schauen",
    "desc-end":
      "Als Open-Source-Projekt respektieren wir Ihr Recht auf Privatsphäre. Wir sind bestrebt, die beste Lösung für die private und sichere Integration von KI und Dokumenten zu entwickeln. Wenn Sie sich entscheiden, die Telemetrie auszuschalten, bitten wir Sie, uns Feedback und Gedanken zu senden, damit wir die Plattform weiterhin für Sie verbessern können.",
  },
  "default-chat": {
    welcome: "Willkommen bei IST Legal.",
    "choose-legal": "Wählen Sie ein Rechtsgebiet auf der linken Seite.",
  },
  invites: {
    title: "Einladungen",
    description:
      "Erstellen Sie Einladungslinks für Personen in Ihrer Organisation, um sich anzumelden und zu registrieren. Einladungen können nur von einem einzigen Benutzer verwendet werden.",
    link: "Einladung-Link erstellen",
    status: "Status",
    accept: "Akzeptiert von",
    "created-by": "Erstellt von",
    created: "Erstellt",
    new: {
      title: "Neue Einladung erstellen",
      "desc-start":
        "Nach der Erstellung können Sie die Einladung kopieren und an einen neuen Benutzer senden, bei dem er ein Konto mit der",
      "desc-mid": "Standard-",
      "desc-end":
        "Rolle erstellen und automatisch zu den ausgewählten Arbeitsbereichen hinzugefügt werden.",
      "auto-add":
        "Einladungsempfänger automatisch zu Arbeitsbereichen hinzufügen",
      "desc-add":
        "Sie können optional den Benutzer automatisch den unten stehenden Arbeitsbereichen zuweisen, indem Sie diese auswählen. Standardmäßig hat der Benutzer keine sichtbaren Arbeitsbereiche. Sie können Arbeitsbereiche später nach der Annahme der Einladung zuweisen.",
      cancel: "Abbrechen",
      "create-invite": "Einladung erstellen",
      error: "Fehler: ",
    },
  },

  "user-menu": {
    edit: "Konto bearbeiten",
    profile: "Profilbild",
    size: "800 x 800",
    "remove-profile": "Profilbild entfernen",
    username: "Benutzername",
    "username-placeholder": "Benutzername des Benutzers",
    "new-password": "Neues Passwort",
    "new-password-placeholder": "neues Passwort",
    cancel: "Abbrechen",
    update: "Konto aktualisieren",
    language: "Bevorzugte Sprache",
  },

  sidebar: {
    thread: {
      "load-thread": "Threads werden geladen....",
      "starting-thread": "Thread wird gestartet...",
      thread: "Neuer Thread",
      delete: "Ausgewählte löschen",
      rename: "Umbenennen",
      "delete-thread": "Thread löschen",
      deleted: "gelöscht",
      default: "Standard",
    },
  },

  embeder: {
    allm: "Verwenden Sie den integrierten Einbettungsanbieter. Keine Einrichtung erforderlich!",
    openai:
      "Die Standardoption für die meisten nicht-kommerziellen Anwendungen.",
    azure:
      "Die Unternehmensoption von OpenAI, die auf Azure-Diensten gehostet wird.",
    localai:
      "Führen Sie Einbettungsmodelle lokal auf Ihrem eigenen Rechner aus.",
    ollama:
      "Führen Sie Einbettungsmodelle lokal auf Ihrem eigenen Rechner aus.",
    lmstudio:
      "Entdecken, herunterladen und führen Sie Tausende von hochmodernen LLMs in wenigen Klicks aus.",
    cohere: "Führen Sie leistungsstarke Einbettungsmodelle von Cohere aus.",
    voyageai:
      "Führen Sie leistungsstarke Einbettungsmodelle von Voyage AI aus.",
  },

  vectordb: {
    lancedb:
      "100% lokale Vektor-Datenbank, die auf derselben Instanz wie unsere Plattform läuft.",
    chroma:
      "Open-Source-Vektor-Datenbank, die Sie selbst hosten oder in der Cloud verwenden können.",
    pinecone:
      "100% cloudbasierte Vektor-Datenbank für Unternehmensanwendungen.",
    zilliz:
      "Cloud-gehostete Vektor-Datenbank, die für Unternehmen mit SOC 2-Konformität entwickelt wurde.",
    qdrant: "Open-Source-lokale und verteilte Cloud-Vektor-Datenbank.",
    weaviate:
      "Open-Source-lokale und cloudgehostete multimodale Vektor-Datenbank.",
    milvus: "Open-Source, hoch skalierbar und blitzschnell.",
    astra: "Vektor-Suche für reale GenAI.",
  },

  system: {
    title: "Systemeinstellungen",
    "desc-start":
      "Dies sind die allgemeinen Einstellungen und Konfigurationen Ihrer Instanz.",
    user: "Benutzer können Arbeitsbereiche löschen",
    "desc-delete":
      "Erlauben Sie normalen Benutzern das Löschen von Arbeitsbereichen, an denen sie beteiligt sind. Dies würde den Arbeitsbereich für alle löschen.",
    limit: "Nachrichten pro Benutzer und Tag begrenzen",
    "desc-limit":
      "Beschränken Sie normale Benutzer auf eine Anzahl erfolgreicher Anfragen oder Chats innerhalb eines 24-Stunden-Fensters. Aktivieren Sie dies, um zu verhindern, dass Benutzer die Kosten von OpenAI in die Höhe treiben.",
    "limit-day": "Nachrichtenlimit pro Tag",
    "custom-tab": "Tab-Namen Anpassen",
  },

  "user-setting": {
    description:
      "Dies sind alle Konten, die auf dieser Instanz ein Konto haben. Das Entfernen eines Kontos entfernt sofort deren Zugang zu dieser Instanz.",
    "add-user": "Benutzer hinzufügen",
    username: "Benutzername",
    role: "Rolle",
    "date-added": "Hinzugefügt am",
  },

  support: {
    title: "Support-E-Mail",
    description:
      "Legen Sie die Support-E-Mail-Adresse fest, die im Benutzer-Menü angezeigt wird, während Sie bei dieser Instanz angemeldet sind.",
    clear: "Löschen",
    save: "Speichern",
  },

  "public-mode": {
    enable: "Öffentlichen Benutzer-Modus aktivieren",
    enabled: "Öffentlicher Benutzer-Modus ist aktiviert",
  },

  button: {
    delete: "Löschen",
    edit: "Bearbeiten",
    suspend: "Suspendieren",
    unsuspend: "Wiederherstellen",
    save: "Speichern",
    accept: "Akzeptieren",
    decline: "Ablehnen",
  },

  "new-user": {
    title: "Benutzer zur Instanz hinzufügen",
    username: "Benutzername",
    "username-ph": "Benutzername des Benutzers",
    password: "Passwort",
    "password-ph": "Initiales Passwort des Benutzers",
    role: "Rolle",
    default: "Standard",
    manager: "Manager",
    admin: "Administrator",
    description:
      "Nach dem Erstellen eines Benutzers muss dieser sich mit seinen initialen Anmeldedaten anmelden, um Zugriff zu erhalten.",
    cancel: "Abbrechen",
    "add-User": "Benutzer hinzufügen",
    error: "Fehler: ",
    "invalid-username":
      "Der Benutzername darf nur Kleinbuchstaben, Zahlen, Unterstriche (_), Punkte (.), @-Symbole und Bindestriche (-) enthalten",
    permissions: {
      title: "Berechtigungen",
      default: [
        "Kann nur mit Arbeitsbereichen chatten, denen er von Administratoren oder Managern hinzugefügt wurde.",
        "Kann keine Einstellungen ändern.",
      ],
      manager: [
        "Kann alle Arbeitsbereiche anzeigen, erstellen und löschen sowie arbeitsbereichsspezifische Einstellungen ändern.",
        "Kann neue Benutzer zur Instanz erstellen, aktualisieren und einladen.",
        "Kann keine LLM-, VectorDB-, Einbettungs- oder anderen Verbindungen ändern.",
      ],
      admin: [
        "Höchstes Benutzerprivileg.",
        "Kann alles im System sehen und tun.",
      ],
    },
  },

  "new-embed": {
    title: "Neues Embed für Arbeitsbereich erstellen",
    error: "Fehler: ",
    "desc-start":
      "Nach dem Erstellen eines Embeds erhalten Sie einen Link, den Sie mit einem einfachen",
    script: "Script",
    tag: "Tag auf Ihrer Website veröffentlichen können.",
    cancel: "Abbrechen",
    "create-embed": "Embed erstellen",
    workspace: "Arbeitsbereich",
    "desc-workspace":
      "Dies ist der Arbeitsbereich, auf dem Ihr Chat-Fenster basiert. Alle Standardwerte werden vom Arbeitsbereich übernommen, es sei denn, sie werden durch diese Konfiguration überschrieben.",
    "allowed-chat": "Erlaubte Chat-Methode",
    "desc-query":
      "Stellen Sie ein, wie Ihr Chatbot arbeiten soll. Bei der Abfrage antwortet er nur, wenn ein Dokument bei der Beantwortung der Anfrage hilft.",
    "desc-chat":
      "Chat öffnet den Chat auch für allgemeine Fragen und kann völlig unzusammenhängende Anfragen zu Ihrem Arbeitsbereich beantworten.",
    "desc-response": "Chat: Beantwortet alle Fragen unabhängig vom Kontext",
    "query-response":
      "Abfrage: Antwortet nur auf Chats, die sich auf Dokumente im Arbeitsbereich beziehen",
    restrict: "Anfragen von Domains einschränken",
    filter:
      "Dieser Filter blockiert alle Anfragen, die von einer anderen Domain als der unten stehenden Liste stammen.",
    "use-embed":
      "Wenn dieses Feld leer bleibt, kann jeder Ihr Embed auf jeder Website verwenden.",
    "max-chats": "Maximale Chats pro Tag",
    "limit-chats":
      "Begrenzen Sie die Anzahl der Chats, die dieser eingebettete Chat in einem Zeitraum von 24 Stunden verarbeiten kann. Null ist unbegrenzt.",
    "chats-session": "Maximale Chats pro Sitzung",
    "limit-chats-session":
      "Begrenzen Sie die Anzahl der Chats, die ein Sitzungsbenutzer mit diesem Embed in einem Zeitraum von 24 Stunden senden kann. Null ist unbegrenzt.",
    "enable-dynamic": "Dynamische Modellnutzung aktivieren",
    "llm-override":
      "Erlauben Sie das Setzen des bevorzugten LLM-Modells, um den Standard des Arbeitsbereichs zu überschreiben.",
    "llm-temp": "Dynamische LLM-Temperatur aktivieren",
    "desc-temp":
      "Erlauben Sie das Setzen der LLM-Temperatur, um den Standard des Arbeitsbereichs zu überschreiben.",
    "prompt-override": "Prompt-Override aktivieren",
    "desc-override":
      "Erlauben Sie das Setzen des System-Prompts, um den Standard des Arbeitsbereichs zu überschreiben.",
  },

  "show-toast": {
    "recovery-codes": "Wiederherstellungscodes in die Zwischenablage kopiert",
    "scraping-website": "Website wird gescraped - dies kann eine Weile dauern.",
    "fetching-transcript": "Transkript für YouTube-Video wird abgerufen.",
    "updating-workspace": "Arbeitsbereich wird aktualisiert...",
    "workspace-updated": "Arbeitsbereich erfolgreich aktualisiert.",
    "link-uploaded": "Link erfolgreich hochgeladen.",
    "password-reset": "Passwort erfolgreich zurückgesetzt.",
    "invalid-reset": "Ungültiger Rücksetz-Token.",
    "delete-option": "Thread konnte nicht gelöscht werden!",
    "thread-deleted": "Thread erfolgreich gelöscht!",
    "picture-uploaded": "Profilbild hochgeladen.",
    "profile-updated": "Profil aktualisiert.",
    "logs-cleared": "Ereignisprotokolle erfolgreich gelöscht.",
    "preferences-updated": "Systemeinstellungen erfolgreich aktualisiert.",
    "user-created": "Benutzer erfolgreich erstellt.",
    "user-deleted": "Benutzer aus dem System gelöscht.",
    "workspaces-saved": "Arbeitsbereiche erfolgreich gespeichert!",
    "failed-workspaces":
      "Speichern der Arbeitsbereiche fehlgeschlagen. Bitte versuchen Sie es erneut.",
    "api-deleted": "API-Schlüssel dauerhaft gelöscht.",
    "api-copied": "API-Schlüssel in die Zwischenablage kopiert.",
    "appname-updated": "Benutzerdefinierter App-Name erfolgreich aktualisiert.",
    "language-updated": "Sprache erfolgreich aktualisiert.",
    "palette-updated": "Palette erfolgreich aktualisiert.",
    "image-uploaded": "Bild erfolgreich hochgeladen.",
    "image-removed": "Bild erfolgreich entfernt.",
    "updated-welcome": "Willkommensnachrichten erfolgreich aktualisiert.",
    "updated-footer": "Footer-Icons erfolgreich aktualisiert.",
    "updated-paragraph":
      "Benutzerdefinierter Absatztext erfolgreich aktualisiert.",
    "updated-supportemail": "Support-E-Mail erfolgreich aktualisiert.",
    "stt-success": "Sprache-zu-Text-Einstellungen erfolgreich gespeichert.",
    "tts-success": "Text-zu-Sprache-Einstellungen erfolgreich gespeichert.",
    "failed-chats-export": "Export der Chats fehlgeschlagen.",
    "cleared-chats": "Alle Chats gelöscht.",
    "embed-deleted": "Embed aus dem System gelöscht.",
    "snippet-copied": "Snippet in die Zwischenablage kopiert!",
    "embed-updated": "Embed erfolgreich aktualisiert.",
    "embedding-saved": "Einbettungseinstellungen erfolgreich gespeichert.",
    "chunking-settings":
      "Einstellungen für Textchunking-Strategie gespeichert.",
    "llm-saved": "LLM-Einstellungen erfolgreich gespeichert.",
    "multiuser-enabled": "Multi-User-Modus erfolgreich aktiviert.",
    "publicuser-enabled": "Public-User-Modus erfolgreich aktiviert.",
    "publicuser-disabled": "Public-User-Modus erfolgreich deaktiviert.",
    "page-refresh": "Ihre Seite wird in wenigen Sekunden aktualisiert.",
    "transcription-saved":
      "Transkriptionseinstellungen erfolgreich gespeichert.",
    "vector-saved": "Vektordatenbank-Einstellungen erfolgreich gespeichert.",
    "workspace-not-deleted": "Arbeitsbereich konnte nicht gelöscht werden!",
    "maximum-messages": "Maximal 4 Nachrichten erlaubt.",
    "users-updated": "Benutzer erfolgreich aktualisiert.",
    "vectordb-not-reset":
      "Vektordatenbank des Arbeitsbereichs konnte nicht zurückgesetzt werden!",
    "vectordb-reset":
      "Vektordatenbank des Arbeitsbereichs wurde zurückgesetzt!",
    "meta-data-update": "Site-Einstellungen erfolgreich aktualisiert!",
    "linked-workspaces-updated":
      "Verknüpfte Arbeitsbereiche erfolgreich aktualisiert.",
  },

  "llm-selection-privacy": {
    openai: {
      description: [
        "Ihre Chats werden nicht für das Training verwendet",
        "Ihre Eingabeaufforderungen und Dokumententexte, die zur Erstellung von Antworten verwendet werden, sind für OpenAI sichtbar",
      ],
    },
    azure: {
      description: [
        "Ihre Chats werden nicht für das Training verwendet",
        "Ihre Texte und Einbettungstexte sind für OpenAI oder Microsoft nicht sichtbar",
      ],
    },
    anthropic: {
      description: [
        "Ihre Chats werden nicht für das Training verwendet",
        "Ihre Eingabeaufforderungen und Dokumententexte, die zur Erstellung von Antworten verwendet werden, sind für Anthropic sichtbar",
      ],
    },
    gemini: {
      description: [
        "Ihre Chats werden anonymisiert und für das Training verwendet",
        "Ihre Eingabeaufforderungen und Dokumententexte, die zur Erstellung von Antworten verwendet werden, sind für Google sichtbar",
      ],
    },
    lmstudio: {
      description: [
        "Ihr Modell und Ihre Chats sind nur auf dem Server zugänglich, der LMStudio ausführt",
      ],
    },
    localai: {
      description: [
        "Ihr Modell und Ihre Chats sind nur auf dem Server zugänglich, der LocalAI ausführt",
      ],
    },
    ollama: {
      description: [
        "Ihr Modell und Ihre Chats sind nur auf der Maschine zugänglich, die Ollama-Modelle ausführt",
      ],
    },
    native: {
      description: [
        "Ihr Modell und Ihre Chats sind nur auf dieser unsere Plattform zugänglich",
      ],
    },
    togetherai: {
      description: [
        "Ihre Chats werden nicht für das Training verwendet",
        "Ihre Eingabeaufforderungen und Dokumententexte, die zur Erstellung von Antworten verwendet werden, sind für TogetherAI sichtbar",
      ],
    },
    mistral: {
      description: [
        "Ihre Eingabeaufforderungen und Dokumententexte, die zur Erstellung von Antworten verwendet werden, sind für Mistral sichtbar",
      ],
    },
    huggingface: {
      description: [
        "Ihre Eingabeaufforderungen und Dokumententexte, die zur Erstellung von Antworten verwendet werden, werden an Ihren von HuggingFace verwalteten Endpunkt gesendet",
      ],
    },
    perplexity: {
      description: [
        "Ihre Chats werden nicht für das Training verwendet",
        "Ihre Eingabeaufforderungen und Dokumententexte, die zur Erstellung von Antworten verwendet werden, sind für Perplexity AI sichtbar",
      ],
    },
    openrouter: {
      description: [
        "Ihre Chats werden nicht für das Training verwendet",
        "Ihre Eingabeaufforderungen und Dokumententexte, die zur Erstellung von Antworten verwendet werden, sind für OpenRouter sichtbar",
      ],
    },
    groq: {
      description: [
        "Ihre Chats werden nicht für das Training verwendet",
        "Ihre Eingabeaufforderungen und Dokumententexte, die zur Erstellung von Antworten verwendet werden, sind für Groq sichtbar",
      ],
    },
    koboldcpp: {
      description: [
        "Ihr Modell und Ihre Chats sind nur auf dem Server zugänglich, der KoboldCPP ausführt",
      ],
    },
    textgenwebui: {
      description: [
        "Ihr Modell und Ihre Chats sind nur auf dem Server zugänglich, der die Oobabooga Text Generation Web UI ausführt",
      ],
    },
    "generic-openai": {
      description: [
        "Daten werden gemäß den Nutzungsbedingungen Ihres generischen Endpunktanbieters geteilt.",
      ],
    },
    cohere: {
      description: [
        "Daten werden gemäß den Nutzungsbedingungen von cohere.com und den Datenschutzgesetzen Ihrer Region geteilt.",
      ],
    },
    litellm: {
      description: [
        "Ihr Modell und Ihre Chats sind nur auf dem Server zugänglich, der LiteLLM ausführt",
      ],
    },
  },

  "vector-db-privacy": {
    chroma: {
      description: [
        "Ihre Vektoren und Dokumententexte werden auf Ihrer Chroma-Instanz gespeichert",
        "Der Zugriff auf Ihre Instanz wird von Ihnen verwaltet",
      ],
    },
    pinecone: {
      description: [
        "Ihre Vektoren und Dokumententexte werden auf den Servern von Pinecone gespeichert",
        "Der Zugriff auf Ihre Daten wird von Pinecone verwaltet",
      ],
    },
    qdrant: {
      description: [
        "Ihre Vektoren und Dokumententexte werden auf Ihrer Qdrant-Instanz gespeichert (Cloud oder selbst gehostet)",
      ],
    },
    weaviate: {
      description: [
        "Ihre Vektoren und Dokumententexte werden auf Ihrer Weaviate-Instanz gespeichert (Cloud oder selbst gehostet)",
      ],
    },
    milvus: {
      description: [
        "Ihre Vektoren und Dokumententexte werden auf Ihrer Milvus-Instanz gespeichert (Cloud oder selbst gehostet)",
      ],
    },
    zilliz: {
      description: [
        "Ihre Vektoren und Dokumententexte werden in Ihrem Zilliz-Cloud-Cluster gespeichert.",
      ],
    },
    astra: {
      description: [
        "Ihre Vektoren und Dokumententexte werden in Ihrer Cloud-AstraDB-Datenbank gespeichert.",
      ],
    },
    lancedb: {
      description: [
        "Ihre Vektoren und Dokumententexte werden privat auf dieser Instanz von unsere Plattform gespeichert",
      ],
    },
  },

  "embedding-engine-privacy": {
    native: {
      description: [
        "Ihre Dokumententexte werden privat auf dieser Instanz von unsere Plattform eingebettet",
      ],
    },
    openai: {
      description: [
        "Ihre Dokumententexte werden an die OpenAI-Server gesendet",
        "Ihre Dokumente werden nicht für das Training verwendet",
      ],
    },
    azure: {
      description: [
        "Ihre Dokumententexte werden an Ihren Microsoft Azure-Dienst gesendet",
        "Ihre Dokumente werden nicht für das Training verwendet",
      ],
    },
    localai: {
      description: [
        "Ihre Dokumententexte werden privat auf dem Server eingebettet, der LocalAI ausführt",
      ],
    },
    ollama: {
      description: [
        "Ihre Dokumententexte werden privat auf dem Server eingebettet, der Ollama ausführt",
      ],
    },
    lmstudio: {
      description: [
        "Ihre Dokumententexte werden privat auf dem Server eingebettet, der LMStudio ausführt",
      ],
    },
    cohere: {
      description: [
        "Daten werden gemäß den Nutzungsbedingungen von cohere.com und den Datenschutzgesetzen Ihrer Region geteilt.",
      ],
    },
    voyageai: {
      description: [
        "An die Server von Voyage AI gesendete Daten werden gemäß den Nutzungsbedingungen von voyageai.com geteilt.",
      ],
    },
  },

  "prompt-validate": {
    edit: "Bearbeiten",
    response: "Antwort",
    prompt: "Aufforderung",
    regenerate: "Antwort neu generieren",
    good: "Gute Antwort",
    bad: "Schlechte Antwort",
    copy: "Kopieren",
    more: "Weitere Aktionen",
    fork: "Gabeln",
    delete: "Löschen",
    cancel: "Abbrechen",
    save: "Speichern & Senden",
  },

  citations: {
    show: "Zitate anzeigen",
    hide: "Zitate verbergen",
    chunk: "Zitatteile",
    pdr: "Hauptdokument abgerufen",
    "pdr-h": "Hervorhebung des Quelldokuments",
    referenced: "Referenziert",
    times: "Mal.",
    citation: "Zitat",
    match: "Übereinstimmung",
    download:
      "Dieser Browser unterstützt keine PDFs. Bitte laden Sie die PDF herunter, um sie anzusehen:",
    "download-btn": "PDF herunterladen",
  },

  "document-drafting": {
    title: "Dokumenterstellung",
    description: "Steuern Sie Ihre Einstellungen zur Dokumenterstellung.",
    configuration: "Konfiguration",
    "drafting-model": "Entwurfs-LLM",
    enabled: "Dokumenterstellung ist aktiviert",
    disabled: "Dokumenterstellung ist deaktiviert",
    "enabled-toast": "Dokumenterstellung aktiviert",
    "disabled-toast": "Dokumenterstellung deaktiviert",
    "desc-settings":
      "Der Administrator kann die Einstellungen zur Dokumenterstellung für alle Benutzer ändern.",
    "drafting-llm": "Entwurfs-LLM-Präferenz",
    saving: "Speichern...",
    save: "Änderungen speichern",
    "chat-settings": "Chat-Einstellungen",
    "drafting-chat-settings": "Einstellungen für das Dokumenterstellungs-Chat",
    "chat-settings-desc":
      "Steuern Sie das Verhalten der Chat-Funktion für die Dokumenterstellung.",
    "drafting-prompt": "Systemaufforderung zur Dokumentenerstellung",
    "drafting-prompt-desc":
      "Die Systemaufforderung, die bei der Dokumentenerstellung verwendet wird, unterscheidet sich von der Aufforderung des juristischen Frage-und-Antwort-Systems. Die Systemaufforderung zur Dokumentenerstellung definiert den Kontext und die Anweisungen, damit die KI eine Antwort generieren kann. Sie sollten eine sorgfältig formulierte Aufforderung bereitstellen, damit die KI eine relevante und genaue Antwort generieren kann.",
  },

  modale: {
    document: {
      title: "Meine Dokumente",
      document: "Dokumente",
      search: "Nach Dokument suchen",
      folder: "Neuer Ordner",
      name: "Name",
      empty: "Keine Dokumente",
      "move-workspace": "In den Workspace verschieben",
      "doc-processor": "Dokumentenprozessor nicht verfügbar",
      "processor-offline":
        "Wir können Ihre Dateien momentan nicht hochladen, da der Dokumentenprozessor offline ist. Bitte versuchen Sie es später erneut.",
      "drag-drop":
        "Klicken Sie, um hochzuladen oder ziehen Sie Dateien hierher",
      "supported-files":
        "unterstützt Textdateien, CSVs, Tabellenkalkulationen, Audiodateien und mehr!",
      "submit-link": "oder einen Link einreichen",
      fetch: "Website abrufen",
      fetching: "Abrufen...",
      "file-desc":
        "Diese Dateien werden an den Dokumentenprozessor hochgeladen, der auf dieser Instanz läuft. Diese Dateien werden nicht an Dritte gesendet oder mit Dritten geteilt.",
      cost: "*Einmalige Kosten für Einbettungen",
      "save-embed": "Speichern und Einbetten",
      "loading-message": "Dies kann bei großen Dokumenten etwas länger dauern",
      "failed-uploads":
        "Die folgenden Dateien konnten nicht hochgeladen werden",
    },
    connectors: {
      title: "Datenverbinder",
      search: "Nach Datenverbindern suchen",
      empty: "Keine Datenverbinder gefunden.",
    },
  },

  dataConnectors: {
    github: {
      name: "GitHub-Repo",
      description:
        "Importieren Sie ein komplettes öffentliches oder privates GitHub-Repository mit einem einzigen Klick.",
      url: "GitHub-Repo-URL",
      "collect-url": "URL des GitHub-Repos, das Sie sammeln möchten.",
      "access-token": "GitHub-Zugriffstoken",
      optional: "optional",
      "rate-limiting": "Zugriffstoken zur Vermeidung von Rate-Limits.",
      "desc-picker":
        "Sobald der Vorgang abgeschlossen ist, stehen alle Dateien im Dokumentenauswahlwerkzeug zur Einbettung in Workspaces zur Verfügung.",
      branch: "Branch",
      "branch-desc": "Branch, aus dem Sie Dateien sammeln möchten.",
      "branch-loading": "-- verfügbare Branches werden geladen --",
      "desc-start": "Ohne Angabe des",
      "desc-token": "GitHub-Zugriffstokens",
      "desc-connector": "kann dieser Datenverbinder nur die",
      "desc-level": "obersten",
      "desc-end":
        "Dateien des Repos sammeln, aufgrund der Rate-Limits der öffentlichen GitHub-API.",
      "personal-token":
        "Erhalten Sie hier einen kostenlosen Personal Access Token mit einem GitHub-Konto.",
      without: "Ohne einen",
      "personal-token-access": "Personal Access Token",
      "desc-api":
        ", kann die GitHub-API die Anzahl der zu sammelnden Dateien aufgrund von Rate-Limits einschränken. Sie können",
      "temp-token": "einen temporären Zugriffstoken erstellen",
      "avoid-issue": "um dieses Problem zu vermeiden.",
      submit: "Senden",
      "collecting-files": "Dateien werden gesammelt...",
    },
    "youtube-transcript": {
      name: "YouTube-Transkript",
      description:
        "Importieren Sie die Transkription eines gesamten YouTube-Videos von einem Link.",
      url: "YouTube-Video-URL",
      "url-video": "URL des YouTube-Videos, das Sie transkribieren möchten.",
      collect: "Transkript sammeln",
      collecting: "Transkript wird gesammelt...",
      "desc-end":
        "Sobald der Vorgang abgeschlossen ist, steht die Transkription im Dokumentenauswahlwerkzeug zur Einbettung in Workspaces zur Verfügung.",
    },
    "website-depth": {
      name: "Massenlink-Scraper",
      description:
        "Scrapen Sie eine Website und ihre Unterlinks bis zu einer bestimmten Tiefe.",
      url: "Website-URL",
      "url-scrape": "URL der Website, die Sie scrapen möchten.",
      depth: "Tiefe",
      "child-links":
        "Dies ist die Anzahl der Unterlinks, denen der Worker von der ursprünglichen URL folgen soll.",
      "max-links": "Maximale Links",
      "links-scrape": "Maximale Anzahl an Links, die gescrapt werden sollen.",
      scraping: "Website wird gescrapt...",
      submit: "Senden",
      "desc-scrap":
        "Sobald der Vorgang abgeschlossen ist, stehen alle gescrapten Seiten im Dokumentenauswahlwerkzeug zur Einbettung in Workspaces zur Verfügung.",
    },
    confluence: {
      name: "Confluence",
      description:
        "Importieren Sie eine gesamte Confluence-Seite mit einem einzigen Klick.",
      url: "Confluence-Seiten-URL",
      "url-page": "URL einer Seite im Confluence-Bereich.",
      username: "Confluence-Benutzername",
      "own-username": "Ihr Confluence-Benutzername.",
      token: "Confluence-Zugriffstoken",
      "desc-start":
        "Sie müssen ein Zugriffstoken zur Authentifizierung bereitstellen. Sie können ein Zugriffstoken",
      here: "hier",
      access: "Zugriffstoken für die Authentifizierung.",
      collecting: "Seiten werden gesammelt...",
      submit: "Senden",
      "desc-end":
        "Sobald der Vorgang abgeschlossen ist, stehen alle Seiten zur Einbettung in Workspaces zur Verfügung.",
    },
  },

  module: {
    "legal-qa": "Rechtliche Fragen und Antworten",
    "document-drafting": "Dokumentenerstellung",
  },

  "fine-tune": {
    title: "Sie haben genügend Daten für ein Feintuning!",
    link: "klicken, um mehr zu erfahren",
    dismiss: "schließen",
  },

  mobile: {
    disclaimer:
      "HAFTUNGSAUSSCHLUSS: Für das beste Erlebnis und den vollen Zugriff auf alle Funktionen, verwenden Sie bitte einen Computer, um auf die App zuzugreifen.",
  },

  "default-settings": {
    title: "Standardeinstellungen für Rechtliche Fragen & Antworten",
    "default-desc":
      "Steuern Sie das Standardverhalten von Arbeitsbereichen für Rechtliche Fragen & Antworten",
    prompt: "Rechtliches Fragen & Antworten System-Prompt",
    "prompt-desc":
      "Das Standard-Prompt, das für Rechtliche Fragen & Antworten verwendet wird. Definieren Sie den Kontext und die Anweisungen, damit die KI eine Antwort generieren kann. Sie sollten ein sorgfältig formuliertes Prompt bereitstellen, damit die KI eine relevante und genaue Antwort generieren kann.",
    "toast-success": "Standard-System-Prompt aktualisiert",
    "toast-fail": "System-Prompt konnte nicht aktualisiert werden",
    snippets: {
      title: "Standard für maximale Kontextausschnitte",
      description:
        "Diese Einstellung steuert die maximale Anzahl von Kontextausschnitten, die pro Chat oder Anfrage an das LLM gesendet werden.",
      recommend: "Empfohlen: 10",
    },
  },
  "confirm-message": {
    "delete-doc":
      "Möchten Sie diese Dateien und Ordner wirklich löschen?\nDies wird die Dateien aus dem System entfernen und automatisch aus allen vorhandenen Arbeitsbereichen entfernen.\nDiese Aktion ist nicht umkehrbar.",
  },

  "pdr-settings": {
    title: "PDR-Einstellungen",
    description:
      "Steuern Sie die dynamischen Token-Limit-Mechanismen für den PDR-Algorithmus",
    "desc-end":
      "Sie sollten diese Einstellung nur ändern, wenn Sie verstehen, wie der PDR-Algorithmus funktioniert.",
    "pdr-token-limit": "PDR-Token-Grenze",
    "pdr-token-limit-desc":
      "Die maximale Anzahl von Tokens, die für den PDR-Algorithmus verwendet wird.",
    "input-prompt-token-limit": "Token-Grenze für Eingabeaufforderung",
    "input-prompt-token-limit-desc":
      "Die maximale Anzahl von Tokens, die für die Eingabeaufforderung verwendet wird.",
    "response-token-limit": "Token-Grenze für Antwort",
    "response-token-limit-desc":
      "Die maximale Anzahl von Tokens, die für die Antwort verwendet wird.",
    "toast-success": "PDR-Einstellungen aktualisiert",
    "toast-fail": "Fehler beim Aktualisieren der PDR-Einstellungen",
  },
};

export default TRANSLATIONS;
