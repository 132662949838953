import { GrAttachment } from "react-icons/gr";

const TRANSLATIONS = {
  common: {
    "workspaces-name": "Izina ry'uburyo bwo gukora",
    error: "ikosa",
    success: "intsinzi",
    user: "Umukoresha",
    selection: "Guhitamo Modeli",
    saving: "Kubika...",
    save: "Bika impinduka",
    previous: "Urupapuro rwabanje",
    next: "Urupapuro rukurikira",
  },

  // Popup Confirmation
  deleteWorkspaceConfirmation:
    "Urabyizeye ko ushaka gusiba {{name}}?\nNyuma y'ibi, ntabwo izaboneka muri iyi porogaramu.\n\nIbi ntibishobora guhindurwa.",
  deleteConfirmation:
    "Urabyizeye ko ushaka gusiba {{username}}?\nNyuma y'ibi, bazasohoka muri konti yabo kandi ntibazashobora kongera gukoresha iyi porogaramu.\n\nIbi ntibishobora guhindurwa.",
  suspendConfirmation:
    "Urabyizeye ko ushaka guhagarika {{username}}?\nNyuma y'ibi, bazasohoka muri konti yabo kandi ntibazashobora kongera kwinjira muri iyi porogaramu kugeza igihe umuyobozi azaba yongeye kubemerera.",

  // Setting Sidebar menu items.
  settings: {
    title: "Igenamiterere rya Instance",
    system: "Igenamiterere rusange",
    invites: "Ubutumire",
    users: "Abakoresha",
    workspaces: "Ahantu ho gukorera",
    "workspace-chats": "Ibiganiro by'ahantu ho gukorera",
    customization: "Guhindura",
    "api-keys": "API y'Abakora",
    llm: "LLM",
    transcription: "Gushyira mu nyandiko",
    embedder: "Inkoranyabitabo",
    "text-splitting": "Gusiga no Gukata Ijambo",
    "vector-database": "Dabase y'Urutonde",
    embeds: "Gushyira mu Kiganiro",
    "embed-chats": "Inyandiko z'Ibiganiro",
    security: "Umutekano",
    "event-logs": "Ibyanditswe by'Ibikorwa",
    privacy: "Ubwiru & Amakuru",
    "ai-providers": "Abatanga AI",
    "agent-skills": "Ubushobozi bw'Umukozi",
    admin: "Ubuyobozi",
    tools: "Ibikoresho",
    audio: "Impamvu z'Ijwi",
    "link-settings": "Igenamiterere",
    "default-settings": "Igenamiterere Rya Default",
    "browser-extension": "Browser Extension",
  },

  // Qura buttons,
  qura: {
    "copy-to-cora": "Genzura inkomoko hamwe na Qura",
    "qura-status": "Akabuto ka Qura ni ",
    "copy-option": "Gukoporora",
    "option-quest": "Ikibazo",
    "option-resp": "Igisubizo",
    "role-description":
      "Ongeraho buto ya Qura kugirango uhite usubiza kuri Qura.law",
  },

  // Page Definitions
  login: {
    "multi-user": {
      welcome: "Murakaza neza kuri",
      "placeholder-username": "Izina ry'Umukoresha",
      "placeholder-password": "Ijambo ry'Ibanga",
      login: "Injira",
      validating: "Kwemeza...",
      "forgot-pass": "Wibagiwe ijambo ry'ibanga",
      reset: "Subiramo",
    },
    "sign-in": {
      start: "Injira muri konti yawe",
      end: "account.",
    },
    button: "injira",
    password: {
      forgot: "WIBAGIWE IJAMBO RY'IBANGA?",
      contact: "Nyamuneka hamagara umuyobozi w'uburyo.",
    },
    publicMode: "Uburyo rusange",
  },

  "new-workspace": {
    title: "Uburyo bushya bwo gukora",
    placeholder: "Uburyo bwanjye bwo gukora",
    "legal-areas": "Inzego z'Amategeko",
    create: {
      title: "Shiraho ahantu hashya ho gukorera",
      description:
        "Nyuma yo gushyiraho aka gahunda, abazoberezi gusa ni bo bazakabona. Ushobora kongeraho abakoresha nyuma yo gushyiraho.",
      error: "Ikosa : ",
      cancel: "Kumva",
      "create-workspace": "Shiraho ahantu ho gukorera",
    },
  },

  "workspace-chats": {
    welcome: "Murakaza neza mu buryo bushya bwo gukora.",
    "desc-start": "Kugira ngo utangire, niyo",
    "desc-mid": "ushyira inyandiko",
    "desc-or": "cyangwa",
    start: "Kugira ngo utangire",
    "desc-end": "wohereza igitekerezo.",
    prompt: {
      send: "Ohereza",
      "send-message": "Ohereza ubutumwa",
      placeholder: "Saba amakuru y'ubujyanama bw'amategeko",
      slash: "Reba amabwiriza yose aboneka yo kuganira.",
      "change-size": "Hindura ubunini bw'inyandiko",
      reset: "/gusubiramo",
      clear: "Siba amateka yawe y'ibiganiro kandi utangire ikiganiro gishya",
      "new-preset": "Ongeraho Preset nshya",
      command: "Itegeko",
      description: "Ibisobanuro",
      save: "bika",
      small: "Ntoya",
      normal: "Isanzwe",
      large: "Nini",
      attach: "Fata dosiye uyishyire muri iki kiganiro",
      upgrade: "Kuzamura itangiriro ryawe",
      upgrading: "Kuvugurura ubutumwa bwawe",
      "original-prompt": "Icyifuzo cya mbere:",
      "upgraded-prompt": "Icyifuzo cyatejwe imbere:",
      "edit-prompt":
        "Ushobora guhindura icyo ushyizemo gishya mbere yo kugitanga",
    },
  },

  header: {
    account: "Konto",
    login: "Kwinjira",
    "sign-out": "Gusohoka",
  },

  workspace: {
    title: "Ibikorwa byo kuri Instance",
    description:
      "Ibi ni ibikorwa byose biba kuri iyi instance. Gukuraho ibikorwa bizavanaho ibiganiro byose n'imitunganyirize yabyo.",
    "new-workspace": "Gikorwa Gishya",
    name: "Izina",
    link: "Uhuza",
    users: "Abakoresha",
    type: "Ubwoko",
    "created-on": "Yakozwe Ku",
    save: "Bika impinduka",
    cancel: "Hagarika",
    deleted: {
      title: "Ahantu ho gukora ntabwo habonetse!",
      description:
        "Bisa nk'aho ahantu ho gukora hifashishwa iri zina ritaboneka.",
      homepage: "Garuka ku rupapuro rw'itangiriro",
    },
  },

  // Workspace Settings menu items
  "workspaces-settings": {
    general: "Igenamiterere Rusange",
    chat: "Igenamiterere rya Chat",
    vector: "Ibigega bya Vector",
    members: "Abanyamuryango",
    agent: "Igenamiterere rya Agent",
    "general-settings": {
      "workspace-name": "Izina ry'Icyumba Cy'Akazi",
      "desc-name": "Ibi bizahindura gusa izina ryerekanwe ry'icyumba cy'akazi.",
      "assistant-profile": "Ishusho y'umwirondoro w'Umufasha",
      "assistant-image":
        "Hindura ishusho y'umwirondoro w'umufasha w'icyumba cy'akazi.",
      "workspace-image": "Ishusho y'Icyumba Cy'Akazi",
      "remove-image": "Kuraho Ishusho y'Icyumba Cy'Akazi",
      delete: "Siba Icyumba Cy'Akazi",
      deleting: "Gusiba Icyumba Cy'Akazi...",
      update: "Hindura ahakorerwa",
      updating: "Birimo guhindura ahakorerwa...",
    },
    "chat-settings": {
      type: "Ubwoko bwa Chat",
      private: "Ihererekanyabubasha",
      standard: "Ibisanzwe",
      "private-desc-start": "izaha uburenganzira bwo kwinjira",
      "private-desc-mid": "bonyine",
      "private-desc-end": "abantu bashyizweho gusa.",
      "standard-desc-start": "izaha uburenganzira bwo kwinjira",
      "standard-desc-mid": "abantu bose",
      "standard-desc-end": "bashya.",
    },
    users: {
      manage: "Gucunga Abakoresha",
      "workspace-member": "Nta banyamuryango b'icyumba cy'akazi",
      username: "Izina ry'umukoresha",
      role: "Uruhare",
      date: "Itariki Yongeweho",
      users: "Abakoresha",
      search: "Shakisha umukozi",
      "no-user": "Nta mukoresha wabonetse",
      select: "Hitamo Byose",
      unselect: "Kuraho Guhitamo",
      save: "Bika",
    },
    "linked-workspaces": {
      title: "Ahantu Habigenewe",
      "linked-workspace": "Nta hantu habigenewe",
      manage: "Shyiramo Ahantu Habigenewe",
      name: "Izina",
      slug: "Izina ry'abigenewe",
      date: "Itariki Yongeweho",
      workspaces: "Ahantu Habigenewe",
      search: "Shakisha ahantu habigenewe",
      "no-workspace": "Nta hantu habigenewe habonetse",
      select: "Hitamo Byose",
      unselect: "Kuraho",
      save: "Bika",
    },
  },

  // General Appearance
  general: {
    vector: {
      title: "Umubare w'Urutonde",
      description: "Umubare wose w'urutonde muri vektordatabas yawe.",
      vectors: "Umubare wa vektori",
    },
    names: {
      description:
        "Ibi bizahindura gusa izina ryerekanwe ry'uburyo bwo gukora bwawe.",
    },
    message: {
      title: "Ubutumwa busabwe mu kiganiro",
      description:
        "Hindura ubutumwa buzajya butangwa ku bakoresha uburyo bwawe bwo gukora.",
      add: "Ongeraho ubutumwa bushya",
      save: "Bika ubutumwa",
      heading: "Sobanurira",
      body: "ibyiza by'uru rubuga",
      message: "Ubutumwa",
      "new-heading": "Ingingo nyamukuru",
    },
    pfp: {
      title: "Ishusho y'Umufasha",
      description: "Hindura ishusho y'umufasha w'uburyo bwo gukora.",
      image: "Ishusho y'uburyo bwo gukora",
      remove: "Kuramo ishusho y'uburyo bwo gukora",
    },
    delete: {
      delete: "Siba uburyo bwo gukora",
      deleting: "Gusiba uburyo bwo gukora...",
      "confirm-start": "Uri hafi yo gusiba uburyo bwawe bwose bwa",
      "confirm-end":
        "uburyo bwo gukora. Ibi bizakuraho byose muri vektordatabas yawe.\n\nIbindi byangombwa by'ubwoko bizaguma bitariho. Iyi bikorwa ntishobora gusubirwamo.",
    },
  },

  // Chat Settings
  chat: {
    llm: {
      title: "Umugabuzi wa LLM w'uburyo bwo gukora",
      description:
        "Umugabuzi n'umusaruro wa LLM uzakoreshwa kuri ubu buryo bwo gukora. By default, bikoresha umugabuzi wa LLM w'uburyo bwa sisitemu n'ibyifuzo.",
      search: "Shakisha abagabuzi bose ba LLM",
    },
    model: {
      title: "Modeli y'ibiganiro y'uburyo bwo gukora",
      description:
        "Umusaruro w'ibiganiro uzakoreshwa kuri ubu buryo bwo gukora. Niba nta kintu, bizakoresha ibyifuzo bya LLM by'uburyo.",
      wait: "-- tegereza ibimenyetso --",
    },
    mode: {
      title: "Imikorere y'ibiganiro",
      chat: {
        title: "Ikiganiro",
        "desc-start": "izatanga ibisubizo n'ubumenyi bwa LLM busanzwe",
        and: "kandi",
        "desc-end": "ibibazo byabonetse.",
      },
      query: {
        title: "Gusaba",
        "desc-start": "izatanga ibisubizo",
        only: "byonyine",
        "desc-end": "niba ibibazo byabonetse.",
      },
    },
    history: {
      title: "Amateka y'ibiganiro",
      "desc-start":
        "Umubare w'ibiganiro byabanjirije bizashyirwa mu byibutsa by'igihe gito by'uburyo bw'ibisubizo.",
      recommend: "Tega 20. ",
      "desc-end":
        "Ibintu birenze 45 birashoboka cyane kuba byatera ibibazo mu kiganiro kubera ubunini bw'ubutumwa.",
    },
    prompt: {
      title: "Ijambo rikoresha",
      description:
        "Ijambo rizakoreshwa kuri ubu buryo bwo gukora. Sobanura ibyifuzo n'amabwiriza ku AI kugira ngo itange igisubizo. Ugomba gutanga ijambo ryakozwe neza kugira ngo AI itange igisubizo gihamye kandi gisobanutse.",
    },
    refusal: {
      title: "Igisubizo cya refusal muri mode ya query",
      "desc-start": "Muri mode ya",
      query: "query",
      "desc-end":
        "ugomba kugarura igisubizo cya refusal igihe nta context yabonetse.",
    },
    temperature: {
      title: "Ubushyuhe bwa LLM",
      "desc-start":
        'Iyi igenamiterere iragena uburyo "kreatif" ibisubizo bya LLM bizaba bimeze.',
      "desc-end":
        "Umubare uri hejuru, ubusumbirizaho. Ku bimenyetso bimwe, ibi birashobora guteza ibisubizo bitari bigufi mugihe byashyizwe hejuru cyane.",
      hint: "Iyi LLM zifite ingero zinyuranye z'ingero zifite agaciro. Shakisha umugabuzi wa LLM.",
    },
  },

  // Vector Database
  "vector-workspace": {
    identifier: "Indangantabire ya Vector",
    snippets: {
      title: "Ibisobanuro bya Maximum Context Snippets",
      description:
        "Iyi igenamiterere iragenzura umubare w'ibyibutsa by'ibibazo byoherejwe kuri LLM ku kiganiro cyangwa gusaba.",
      recommend: "Recommandé: 4",
    },
    doc: {
      title: "Uruhare rwa Document Similarity",
      description:
        "Umubare w'ibyibutsa bisabwa kugira ngo ubutumwa bufate ibyibutsa ku kibazo. Uruhare runini, igice kinini cya response gifite.",
      zero: "Nta cyitonderwa",
      low: "Gukura (score de similarité ≥ .25)",
      medium: "Medi (score de similarité ≥ .50)",
      high: "Hejuru (score de similarité ≥ .75)",
    },
    reset: {
      reset: "Guhindura Vektordatabas",
      resetting: "Gusiba vectors...",
      confirm:
        "Uri hafi yo guhindura Vektordatabas y'uburyo bwo gukora. Ibi bizakuraho vectors byose byinjijwe.\n\nIbindi byangombwa by'ubwoko bizaguma bitariho. Iyi bikorwa ntishobora gusubirwamo.",
      error: "Vektordatabas y'uburyo bwo gukora ntiyashobora gusubizwamo!",
      success: "Vektordatabas y'uburyo bwo gukora yasubizwemo!",
    },
  },

  // Agent Configuration
  agent: {
    "performance-warning":
      "Imikorere y'ibiganiro LLM itarafashijwe neza ni ubuhanga n'uburyo. Ubuhanga buto bushobora kuba butari bwiza cyangwa budakora.",
    provider: {
      title: "Umugabuzi wa LLM w'uburyo bwo gukora",
      description:
        "Umugabuzi n'umusaruro wa LLM uzakoreshwa kuri ubu buryo bwo gukora @agent.",
    },
    mode: {
      chat: {
        title: "Modeli y'ibiganiro by'umukozi w'uburyo bwo gukora",
        description:
          "Modeli y'ibiganiro izakoreshwa kuri ubu buryo bwo gukora @agent.",
      },
      title: "Modeli y'umukozi w'uburyo bwo gukora",
      description:
        "Modeli y'ibiganiro izakoreshwa kuri ubu buryo bwo gukora @agent.",
      wait: "-- tegereza ibimenyetso --",
    },

    skill: {
      title: "Ubuhanga bw'umukozi busanzwe",
      description:
        "Itezimbere ubushobozi busanzwe bw'umukozi usanzwe hamwe n'ubu buhanga bwubatswe. Iyi iteganyijwe ikoreshwa ku buryo bwose bwo gukora.",
      rag: {
        title: "RAG & Kwibuka igihe kirekire",
        description:
          "Reka umukozi akoreshe ibinyabuzima byawe byo kugusubiza cyangwa usabe umukozi 'kwibuka' ibice by'ibintu byo kwibuka igihe kirekire.",
      },
      configure: {
        title: "Gushyiraho Ubumenyi bw'Umukozi",
        description:
          "Hindura no kongera ubushobozi bw'umukozi w'ibanze w'ibanze ukoresheje kwemeza cyangwa kwima ubumenyi bwihariye. Ibi bigenamiterere bizakoreshwa mu byumba byose by'akazi.",
      },
      view: {
        title: "Reba & Subiramo inyandiko",
        description:
          "Reka umukozi agaragaze no gusubiramo ibintu byo muri workspace byinjiye.",
      },
      scrape: {
        title: "Gusoroma ibikubiyemo ku mbuga",
        description: "Reka umukozi asure no gusoroma ibikubiyemo ku mbuga.",
      },
      generate: {
        title: "Gukora grafike",
        description:
          "Tegereza umukozi usanzwe gukora grafike itandukanye y'ibibazo bitangwa cyangwa byatanzwe mu kiganiro.",
      },
      save: {
        title: "Gukora & Kubika dosiye mu murandasi",
        description:
          "Tegereza umukozi usanzwe gukora no kwandika dosiye zibika kandi zishobora gutegurwa mu murandasi.",
      },
      web: {
        title: "Gushakisha & Kwerekeza ku mbuga z'amakuru",
        "desc-start":
          "Tegereza umukozi wawe gushakisha ku mbuga z'amakuru ku bibazo byawe byibwira n'ubushobozi bwa web-surfing (SERP).",
        "desc-end":
          "Gushakisha ku mbuga z'amakuru mu kiganiro cy'umukozi ntibizakora igihe kitazashyirwaho.",
      },
    },
  },

  // Workspace Chats
  recorded: {
    title: "Ibiganiro by'uburyo bwo gukora",
    description:
      "Aha ni ahantu h'ibiganiro byose byanditswe n'abakoresha bitewe n'igihe byashyizweho.",
    export: "Kuramo",
    table: {
      id: "Id",
      by: "Byoherejwe na",
      workspace: "Uburyo bwo gukora",
      prompt: "Ijambo rikoresha",
      response: "Igisubizo",
      at: "Byoherejwe kuri",
    },
    "clear-chats": "Sukura Ibiganiro",
  },

  // Appearance
  appearance: {
    title: "Kugaragara",
    description: "Hindura uburyo bwo kugaragara bw'urubuga rwawe.",
    logo: {
      title: "Hindura Logo",
      description:
        "Shyira logo yawe kugira ngo chat yako bigaragare ko ari iyawe.",
      add: "Ongeraho logo yawe",
      recommended: "Ingano itangwa: 800 x 200",
      remove: "Kuramo",
      replace: "Subiramo",
    },
    message: {
      title: "Hindura ubutumwa",
      description: "Hindura ubutumwa bwikora bwerekanwa kubakoresha bawe.",
      new: "Bushya",
      system: "sisitemu",
      user: "umukoresha",
      message: "ubutumwa",
      assistant: "Chat Assistant",
      "double-click": "Kanda kabiri kugira ngo uhindure...",
      save: "Bika ubutumwa",
    },
    icons: {
      title: "Ikirangantego cy'inyuma",
      description: "Hindura ikirangantego cy'inyuma kiri hasi y'inyuma.",
      icon: "Ikirangantego",
      link: "Inyandiko",
    },
    display: {
      title: "Ururimi rwo Kwerekana",
      description: "Hitamo ururimi rwo kwerekana.",
    },
    color: {
      title: "Amasaro Yihariye",
      "desc-start": "Hindura",
      "desc-mid": "*ibara ry'inyuma, *ibara ry'ibanze",
      "desc-and": "kandi",
      "desc-end": "*ibara ry'inyandiko",
    },
    login: {
      title: "Inyandiko ya Login",
      description: "Hindura igitabo cy'inyandiko yerekana kuri login.",
      save: "bika",
    },
  },

  // API Keys
  api: {
    title: "Imfunguzo za API",
    description:
      "Imfunguzo za API zemerera umukoresha kwinjira no gucunga iyi instance mu buryo bwa program.",
    link: "Soma API Documentation",
    generate: "Kora imfunguzo nshya za API",
    table: {
      key: "Imfunguzo za API",
      by: "Byakozwe na",
      created: "Byakozwe",
    },
    new: {
      title: "Shiraho urufunguzo rushya rwa API",
      description:
        "Nyuma yo gushyiraho, urufunguzo rwa API rushobora gukoreshwa kugira ngo ubone no gukonfigure iyi instance ya mu buryo bwa porogaramu.",
      doc: "Soma inyandiko za API",
      cancel: "Kumva",
      "create-api": "Shiraho urufunguzo rwa API",
    },
  },

  llm: {
    title: "Inzira ya LLM",
    description:
      "Ibi ni amakuru ajyanye na serivisi y'ibanze yo kuganira no gukoresha LLM wahisemo. Ni ingenzi ko aya makuru y'ingenzi ari aya vuba kandi nyayo, ubundi uburyo ntibuzakora neza.",
    provider: "LLM Provider",
    "none-selected": "Nta na kimwe",
    "select-llm": "Ugomba guhitamo LLM",
    "search-llm": "Shakisha abagabuzi bose ba LLM",
  },

  "llm-provider": {
    openai: "Icyitegererezo gisanzwe kubikorwa bidasanzwe by'ubucuruzi.",
    azure:
      "Icyitegererezo cy'ubucuruzi cya OpenAI gihagarariwe na Azure services.",
    anthropic: "Umufasha w'AI w'inshuti wahagarariwe na Anthropic.",
    gemini: "Modèle y'AI nini cyane kandi ifite ubushobozi bwa Google",
    huggingface:
      "Gerageza 150 000+ LLM ifite umutekano n'umuryango wa AI ku isi",
    ollama: "Koresha LLM zigendanwa ku mashini yawe.",
    lmstudio:
      "Gerageza, ikura, no gukoresha imbaga y'abantu ikora LLM imirimo isanzwe y'ibikorwa.",
    localai: "Koresha LLM zigendanwa ku mashini yawe.",
    togetherai: "Koresha imbonerahamwe za Together AI.",
    mistral: "Koresha imbonerahamwe za Mistral AI.",
    perplexityai:
      "Koresha imbonerahamwe zifite ingufu kandi zihujwe n'interineti zashyizweho na Perplexity AI.",
    openrouter: "Imiterere y'inyenyeri ya LLM.",
    groq: "Ibisubizo byihuse bya LLM birangwa na real-time AI applications.",
    koboldcpp: "Koresha LLM zigendanwa ukoresheje koboldcpp.",
    oobabooga:
      "Koresha LLM zigendanwa ukoresheje interface ya Oobabooga Text Generation Web.",
    cohere: "Koresha imbonerahamwe za Cohere Command.",
    lite: "Koresha proxy ya LiteLLM ifite uburyo bwiza bwa OpenAI ku LLM nyinshi.",
    "generic-openai":
      "Shyira mu buryo bwiza na service y'ubucuruzi ya OpenAi biciye mu igenamiterere ry'ubucuruzi",
    native: "Koresha Llama Model y'ubucuruzi y'iyi instance.",
  },

  // audio preference
  audio: {
    title: "Ihitamo rya Kuvuga-ubwanditsi",
    provider: "Umukoresha",
    "desc-speech":
      "Hano ushobora gutangaza ubwoko bwa serivisi za kuvuga-ubwanditsi n'izivuga-kwandika ushaka gukoresha muri platformu yawe. Ku by default, dukoresha ubufasha bw'inzibacyuho bw'umurongo w'ishusho ariko ushobora gukoresha izindi.",
    "title-text": "Ihitamo rya Kuvuga-ubwanditsi",
    "desc-text":
      "Hano ushobora gutangaza ubwoko bwa serivisi za kuvuga-ubwanditsi ushaka gukoresha mu buryo bwa platformu. Ku by default, dukoresha ubufasha bw'inzibacyuho bw'umurongo w'ishusho ariko ushobora gukoresha izindi.",
    "desc-config": "Nta bikorwa byo gushyiraho bigenewe uyu mukoresha.",
    "placeholder-stt": "Shaka abakoresha kuvuga-ubwanditsi",
    "placeholder-tts": "Shaka abakoresha ubwanditsi-kwandika",
    "native-stt":
      "Koresha serivisi ya STT y'umurongo w'ishusho niba ikemerewe.",
    "native-tts":
      "Koresha serivisi ya TTS y'umurongo w'ishusho niba ikemerewe.",
    openai: "Koresha amajwi ya OpenAI ya ubwanditsi-kwandika.",
    elevenlabs:
      "Koresha amajwi n'ikoranabuhanga rya ElevenLabs rya ubwanditsi-kwandika.",
  },

  transcription: {
    title: "Inzira y'Icyitegererezo cya Transcription",
    description:
      "Izi ni ibintu n'igenamiterere yawe ya icyitegererezo cya transcription provider. Birakenewe ko izi mfunguzo ziriho kandi zikwiriye cyangwa dosiye za media na audio ntizizasubirwamo.",
    provider: "Transcription Provider",
    "warn-start":
      "Gukoresha icyitegererezo cya whisper kuri mashini ifite RAM cyangwa CPU bicye cyane bishobora guhagarika urubuga igihe cyo gutegura dosiye za media.",
    "warn-recommend": "Turagusaba byibura 2GB ya RAM kandi dosiye yawe <10Mb.",
    "warn-end":
      "Icyitegererezo cya inbuilt kizahita gikururwa igihe cyo gukoresha bwa mbere.",
    "search-audio": "Shakisha abagabuzi ba transcription audio",
  },

  embedding: {
    title: "Inzira y'Inkoranyabitabo",
    "desc-start":
      "Mugihe ukoresheje LLM idafite inkoranyabitabo yihariye - urashobora kugira uruhare rwiyongereye rw'inkoranyabitabo.",
    "desc-end":
      "Inkoranyabitabo ni uburyo bwo guhindura inyandiko mu rutonde. Izi nkeneye kugira uruhare mu kubyara dosiye na prompts mu ikarito ya urubuga.",
    provider: {
      title: "Inkoranyabitabo",
      description:
        "Nta igenamiterere rikeneye igihe ukoresheje instance inbuilt embedding engine.",
      "search-embed": "Shakisha abagabuzi bose ba embedding",
    },
  },

  text: {
    title: "Text deling & Chunking Preferences",
    "desc-start":
      "Igihe kinini, urashobora guhindura uburyo bwo gushyiramo no kubika mbere yo gukomeza gushyiramo vektordatabas.",
    "desc-end":
      "Ugomba guhindura iyi igenamiterere gusa niba usobanukiwe uburyo bwo gusiga no kubika inyandiko kandi n'ingaruka zayo.",
    "warn-start": "Inyandiko ziri hano zizaba gusa kuri",
    "warn-center": "inyandiko nshya z'ububiko",
    "warn-end": ", ntabwo inyandiko zihari.",
    size: {
      title: "Ingano ya Text Chunk",
      description:
        "Iyi ni uburebure bwa karactere zishobora kuboneka muri vektordatabas imwe.",
      recommend: "Uburebure bwa model y'ububiko ni",
    },

    overlap: {
      title: "Ubwiyongere bwa Text Chunk",
      description:
        "Iyi ni uburebure bwa karactere ziba ziriho mugihe cyo gusiga hagati ya text chunk ebyiri ziri.",
    },
  },

  // Vector Database
  vector: {
    title: "Vektordatabas",
    description:
      "Izi ni ibintu n'igenamiterere yawe ya instance izakora. Birakenewe ko izi mfunguzo ziriho kandi zikwiriye.",
    provider: {
      title: "Vektordatabas",
      description: "Nta igenamiterere rikeneye kuri LanceDB.",
      "search-db": "Shakisha abagabuzi bose ba vektordatabas",
    },
  },

  // Embeddable Chat Widgets
  embeddable: {
    title: "Ibikoresho by'Ikiganiro",
    description:
      "Ibikoresho by'ikiganiro bishobora guhurizwa hamwe ni interface za chat zisanzwe zifite urutonde rw'uburyo bwakozwe. Ibi bifasha kubaka ubuhanga bwa chat bishobora gutegurwa ku isi yose.",
    create: "Kora embedding",
    table: {
      workspace: "Uburyo bwo gukora",
      chats: "Ibiganiro byatanzwe",
      Active: "Domains ziriho",
    },
  },

  "embed-chats": {
    title: "Ibiganiro by'Ikiganiro",
    export: "Export",
    description:
      "Aha ni ibiganiro byose byanditswe n'abakoresha bitumye bashyizweho.",
    table: {
      embed: "Gushyira mu buryo bwo gukora",
      sender: "Ohereza",
      message: "Ubutumwa",
      response: "Igisubizo",
      at: "Byoherejwe kuri",
    },
  },

  multi: {
    title: "Imikorere y'Abantu Benshi",
    description:
      "Tegura instance yawe gushyigikira itsinda ryawe ukoresheje Multi-User Mode.",
    enable: {
      "is-enable": "Imikorere y'Abantu Benshi yashyizweho",
      enable: "Shyira imikorere y'Abantu Benshi",
      description:
        "By default, uzaba umuyobozi wenyine. Nk'umuyobozi ugomba gukora accounts y'abantu bose bashya cyangwa abakozi. Ntakibazo cyawe kijyanye n'ijambo ry'ibanga kubera ko umuyobozi wenyine ashobora gusubiramo ijambo ry'ibanga.",
      username: "Izina ry'umuyobozi w'uburyo bwo gukora",
      password: "Ijambo ry'ibanga rya account y'umuyobozi",
    },
    password: {
      title: "Kurinda Ijambo ry'ibanga",
      description:
        "Kurinda instance ya na password. Niba ushaka irindi jambo ry'ibanga, nta buryo bwo kongera gukosora, nyamuneka ubike ijambo ry'ibanga.",
    },
    instance: {
      title: "Kurinda ijambo ry'ibanga instance",
      description:
        "By default, uzaba umuyobozi wenyine. Nk'umuyobozi ugomba gukora accounts y'abantu bose bashya cyangwa abakozi. Ntakibazo cyawe kijyanye n'ijambo ry'ibanga kubera ko umuyobozi wenyine ashobora gusubiramo ijambo ry'ibanga.",
      password: "Ijambo ry'ibanga rya instance",
    },
  },

  // Event Logs
  event: {
    title: "Ibyanditswe by'Ibikorwa",
    description:
      "Reba ibikorwa byose n'ibikorwa bibera kuri instance iriho kugira ngo ugenzure.",
    clear: "Siba ibyanditswe by'Ibikorwa",
    table: {
      type: "Ubwoko bw'Ibikorwa",
      user: "Umukoresha",
      occurred: "Byabaye kuri",
    },
  },

  // Privacy & Data-Handling
  privacy: {
    title: "Ubwiru & Gukora ku Makuru",
    description:
      "Iyi ni configuration yawe ku bijyanye n'uburyo abatanga service ziriho na urubuga rwacu bakora ku makuru yawe.",
    llm: "Guhitamo LLM",
    embedding: "Inzira y'Inkoranyabitabo",
    vector: "Vektordatabas",
    anonymous: "Telemetry y'Ubwiru yashyizweho",
    "desc-event": "Ibikorwa byose ntibizandika IP-adresse kandi ntibikubiyemo",
    "desc-id": "gushushanya",
    "desc-cont":
      "by'amakuru, ibyifuzo, ibiganiro, cyangwa andi makuru atariyo. Reba urutonde rw'ibyanditswe by'ibikorwa ushobora kureba kuri",
    "desc-git": "Github hano",
    "desc-end":
      "Nka projet ya open-source, twubaha uburenganzira bwawe bwo kugumana ubwiru. Twiyemeje gukora umuti mwiza wo gushyira hamwe AI n'inyandiko mu bwiru no mu mutekano. Niba ushaka kubika telemetry, turagusaba gusaba kwitabira gutanga ibitekerezo n'ibitekerezo kugira ngo dukomeze kunoza platform kubw'inyungu yawe",
  },
  "default-chat": {
    welcome: "Murakaza neza kuri IST Legal.",
    "choose-legal": "Hitamo ahantu h'ubujyanama bw'amategeko ibumoso.",
  },
  invites: {
    title: "Ubutumire",
    description:
      "Kora links z'ubutumire ku bantu mu rwego rw'umuryango wawe kugira ngo bemere kandi biyandikishe. Ubutumire burashobora gukoreshwa n'umuntu umwe gusa.",
    link: "Kora link y'ubutumire",
    status: "Status",
    accept: "Kwemezwa na",
    "created-by": "Byakozwe na",
    created: "Byakozwe",
    new: {
      title: "Kurema ubutumire bushya",
      "desc-start":
        "Nyuma yo kurema uzashobora gukoporora ubutumire no kubwohereza ku mukoresha mushya aho bashobora gukora konti nka",
      "desc-mid": "defaults",
      "desc-end":
        "rimwe kandi bikazajya byongerwa ku masoko y'akazi yatoranijwe.",
      "auto-add": "Kwongerera umukoresha kubyakozwe",
      "desc-add":
        "Urashobora guhitamo kongera umukoresha kubyakozwe munsi mu gutoranya. Defaults, umukoresha ntazaba afite amasoko y'akazi agaragara. Urashobora gutoranya amasoko y'akazi nyuma yo kwakira ubutumire.",
      cancel: "Guhagarika",
      "create-invite": "Gukora ubutumire",
      error: "Ikosa : ",
    },
  },

  "user-menu": {
    edit: "Hindura Account",
    profile: "Ishusho ya Profil",
    size: "800 x 800",
    "remove-profile": "Kuramo Ishusho ya Profil",
    username: "Izina ry'umukoresha",
    "username-placeholder": "Izina ry'umukoresha",
    "new-password": "Ijambo ry'ibanga rishya",
    "new-password-placeholder": "ijambo ry'ibanga rishya",
    cancel: "Reka",
    update: "Hindura Account",
    language: "Ururimi rukunda",
  },

  sidebar: {
    thread: {
      "load-thread": "Gukora threads....",
      "starting-thread": "Gutanga Thread...",
      thread: "Thread nshya",
      delete: "Siba byatoranyijwe",
      rename: "Hindura izina",
      "delete-thread": "Siba Thread",
      deleted: "yasibwe",
      default: "itangiriro",
      "empty-thread": "Urukurikirane",
      "rename-message": "Ushaka kwita iri zina iki urukurikirane?",
      "delete-message":
        "Urizeye ko ushaka gusiba uru rukurikirane? Ibiganiro byose birimo bizasibwa. Nta bwo ushobora gusubiza inyuma.",
    },
  },

  embeder: {
    allm: "Koresha inkoranyabitabo ya platform. Nta igenamiterere rikeneye!",
    openai: "Icyitegererezo gisanzwe kubikorwa bidasanzwe by'ubucuruzi.",
    azure:
      "Icyitegererezo cy'ubucuruzi cya OpenAI gihagarariwe na Azure services.",
    localai: "Koresha inkoranyabitabo zigendanwa ku mashini yawe.",
    ollama: "Koresha inkoranyabitabo zigendanwa ku mashini yawe.",
    lmstudio:
      "Gerageza, ikura, no gukoresha imbaga y'abantu ikora LLM imirimo isanzwe y'ibikorwa.",
    cohere: "Koresha inkoranyabitabo zifite ubushobozi bwa Cohere.",
    voyageai: "Koresha inkoranyabitabo zifite ubushobozi bwa Voyage AI.",
  },

  vectordb: {
    lancedb:
      "Database ya vector 100% ikorera kuri ubu buryo hamwe na platform.",
    chroma:
      "Database ya vector ifunguye ushyira kuri server yawe cyangwa ku rubuga rwa cloud.",
    pinecone:
      "Database ya vector 100% ikorera ku rubuga rwa cloud ku bigo binini.",
    zilliz:
      "Database ya vector ihujwe n'ijwi rya cloud, yagenewe ibigo binini ifite kubahiriza SOC 2.",
    qdrant:
      "Database ya vector ifunguye, ikorera ahantu hamwe no ku rubuga rwa cloud.",
    weaviate:
      "Database ya vector ifunguye, ikorera ahantu hamwe no ku rubuga rwa cloud, ifite imikorere myinshi.",
    milvus:
      "Ifunguye, ifite ubushobozi bwo gukura cyane, kandi ikora vuba cyane.",
    astra: "Ubushakashatsi bwa vector ku GenAI mu isi nyayo.",
  },

  system: {
    title: "Ibyifuzo by'uburyo",
    "desc-start":
      "Ibi ni ibyo gushyiraho no guhindura uburyo bwose bw'ibanze bwawe.",
    user: "Abakoresha bashobora gusiba ibigo by'imirimo",
    "desc-delete":
      "Emeza abakoresha batari admin gusiba ibigo by'imirimo barimo. Ibi byasiba ikigo cy'imirimo kuri bose.",
    limit: "Gushyiraho umubare w'ubutumwa ku mukoresha buri munsi",
    "desc-limit":
      "Gufata abakoresha batari admin ku mubare w'ibibazo byatsinze cyangwa ibiganiro mu gihe cy'amasaha 24. Hindura ibi kugirango wirinde abakoresha kubyutsa ibiciro bya OpenAI.",
    "limit-day": "Umubare w'ubutumwa ku munsi",
    "custom-tab": "Hindura Izina ry'Umutwe",
  },

  "user-setting": {
    description:
      "Aha niho buri konte iri kuri ubu buryo ibarizwa. Gusiba konte bizahita bisiba uburenganzira bwabo bwo kwinjira kuri ubu buryo.",
    "add-user": "Ongeramo Umukoresha",
    username: "Izina ry'umukoresha",
    role: "Uruhare",
    "date-added": "Igihe byongereweho",
  },
  support: {
    title: "Imeyili y'Ubwunganizi",
    description:
      "Shyiraho aderesi y'imeli y'ubwunganizi igaragara mu menyu y'umukoresha mu gihe winjiye muri iyi nshuro.",
    clear: "Sukura",
    save: "Bika",
  },
  "public-mode": {
    enable: "Shyiraho Uburyo bwa Umukoresha-Mubuyobozi",
    enabled: "Uburyo bwa Umukoresha-Mubuyobozi Bwarashizweho",
  },
  button: {
    delete: "Gusiba",
    edit: "Hindura",
    suspend: "Hagarika",
    unsuspend: "Gusubukura",
    accept: "Kwemera",
    decline: "Kwirengagiza",
  },
  "new-user": {
    title: "Ongeraho umukozi ku ishusho",
    username: "Izina ry'umukoresha",
    "username-ph": "Izina ry'umukoresha",
    password: "Ijambo ry'ibanga",
    "password-ph": "Ijambo ry'ibanga ry'umukoresha",
    "invalid-username":
      "Izina ry'ukoresha rigomba kuba rigizwe n'inyuguti ntoya, imibare, udukubo (_), utudomo (.), @ n'udukoni (-) gusa",
    role: "Umwanya",
    default: "Default",
    manager: "Umuyobozi",
    admin: "Umuyobozi mukuru",
    description:
      "Nyuma yo gushyiraho umukozi, azakenera kwinjira akoresheje ijambo ry'ibanga rye rya mbere kugira ngo abone uburenganzira.",
    cancel: "Kumva",
    "add-User": "Ongeraho umukozi",
    error: "Ikosa : ",
    permissions: {
      title: "Uburenganzira",
      default: [
        "Ashobora kohereza chats gusa mu bice by'akazi yashyizwemo n'umuyobozi cyangwa abashinzwe.",
        "Ntashobora guhindura imiterere iyo ari yo yose.",
      ],
      manager: [
        "Ashobora kureba, gukora, no gusiba bice by'akazi byose no guhindura imiterere y'ibyo bice by'akazi.",
        "Ashobora gukora, kuvugurura no gutumira abakoresha bashya muri iri koranabuhanga.",
        "Ntashobora guhindura LLM, vectorDB, kwinjiza, cyangwa izindi nshamake.",
      ],
      admin: [
        "Ububasha bwo hejuru bw'umukoresha.",
        "Ashobora kureba no gukora byose ku buryo bwose.",
      ],
    },
  },
  "new-embed": {
    title: "Shiraho embed nshya kuri workspace",
    error: "Ikosa: ",
    "desc-start":
      "Nyuma yo gushyiraho embed, uzahabwa umuhora ushobora gushyira ku rubuga rwawe hamwe na",
    script: "script",
    tag: "tag.",
    cancel: "Kumva",
    "create-embed": "Shiraho embed",
    workspace: "Workspace",
    "desc-workspace":
      "Iyi ni workspace chat window yawe izashingiraho. Defaults zose zizaba zaragijwe na workspace keretse ibi bishobora kuba byahinduwe na configuration iyi.",
    "allowed-chat": "Uburyo bwa chat bwemewe",
    "desc-query":
      "Shyiraho uko chatbot yawe ikwiye gukora. Query bivuze ko izasubiza gusa niba hari inyandiko ifasha gusubiza ikibazo.",
    "desc-chat":
      "Chat ifungura chat ku bibazo byose byihariye kandi ishobora gusubiza ibibazo bitari bijyanye na workspace yawe.",
    "desc-response": "Chat: Gusubiza ibibazo byose ntacyo bititaye ku konteksi",
    "query-response":
      "Query: Gusubiza gusa ibibazo bijyanye n'inyandiko muri workspace",
    restrict: "Funga requests zituruka ku masite",
    filter:
      "Uyu muyoboro uzabuza requests zose zituruka ku masite atari ari kuri lisiti iri hasi.",
    "use-embed":
      "Gusiga iki kibanza gisa bisobanuye ko umuntu wese ashobora gukoresha embed yawe ku rubuga urwo ari rwo rwose.",
    "max-chats": "Chats max ku munsi",
    "limit-chats":
      "Shyiraho umubare wa chats iyi embeded chat ishobora kwakira mu gihe cy'amasaha 24. Zero ni ukuvuga ko ntarengwa.",
    "chats-session": "Chats max ku mwanya",
    "limit-chats-session":
      "Shyiraho umubare wa chats umukoresha w'umwanya ashobora kohereza na iyi embed mu gihe cy'amasaha 24. Zero ni ukuvuga ko ntarengwa.",
    "enable-dynamic": "Shyiraho imikoreshereze ya dynamic model",
    "llm-override":
      "Emerera gushyiraho model ya LLM wifuza kugira ngo isimbuze iy'ahantu hasanzwe.",
    "llm-temp": "Shyiraho ubushyuhe bwa dynamic LLM",
    "desc-temp":
      "Emerera gushyiraho ubushyuhe bwa LLM kugira ngo busimbuze iy'ahantu hasanzwe.",
    "prompt-override": "Shyiraho igisimbura Prompt",
    "desc-override":
      "Emerera gushyiraho prompt ya system kugira ngo isimbuze iy'ahantu hasanzwe.",
  },

  "show-toast": {
    "recovery-codes": "Amakode yo gukira yashyizwe mu kibanza cy'ibyo wandika",
    "scraping-website": "Gukanda urubuga rwa web - birashobora gufata igihe.",
    "fetching-transcript": "Gukuraho inyandiko ya videwo ya YouTube.",
    "updating-workspace": "Gushyira mu buryo bushya workspace...",
    "workspace-updated": "Workspace yashyizwe mu buryo bushya neza.",
    "link-uploaded": "Umuhora washyizweho neza",
    "password-reset": "Ijambo ry'ibanga ryasubiwemo neza",
    "invalid-reset": "Ikimenyetso cyo gusubiramo kitari cyiza",
    "delete-option": "Thread ntiyashoboye gusibwa!",
    "thread-deleted": "Thread yakuweho neza!",
    "picture-uploaded": "Ifoto ya profil yashyizweho.",
    "profile-updated": "Profil yashyizwe mu buryo bushya.",
    "logs-cleared": "Ibyo wabonye byabitswe neza.",
    "preferences-updated":
      "Preferences za system zashyizwe mu buryo bushya neza.",
    "user-created": "Umukoresha yashyizweho neza.",
    "user-deleted": "Umukoresha yakuweho mu system.",
    "workspaces-saved": "Workspaces zabitswe neza!",
    "failed-workspaces": "Byanze kubika workspaces. Ongera ugerageze.",
    "api-deleted": "Urufunguzo rwa API rwahanaguwe burundu",
    "api-copied": "Urufunguzo rwa API rwashyizwe mu kibanza cy'ibyo wandika",
    "appname-updated": "Izina rya application ryarahinduwe neza.",
    "language-updated": "Ururimi rwahinduwe neza.",
    "palette-updated": "Amabara yahinduwe neza.",
    "image-uploaded": "Ifoto yashyizweho neza.",
    "image-removed": "Ifoto yakuweho neza.",
    "updated-welcome": "Ubutumwa bw'akaze bushyizweho neza.",
    "updated-footer": "Ibimenyetso bya footer byashyizweho neza.",
    "updated-paragraph": "Inyandiko ya paragarafu yihariye yashyizweho neza.",
    "updated-supportemail": "Imeri y'ubufasha yashyizweho neza.",
    "stt-success": "Preferences za speech-to-text zabitswe neza.",
    "tts-success": "Preferences za text-to-speech zabitswe neza.",
    "failed-chats-export": "Byanze gutanga chats.",
    "cleared-chats": "Chats zose zirahanagurwa.",
    "embed-deleted": "Embed yavanywe muri system.",
    "snippet-copied": "Snippet yashyizwe mu kibanza cy'ibyo wandika!",
    "embed-updated": "Embed yashyizwe mu buryo bushya neza.",
    "embedding-saved": "Preferences za embedding zabitswe neza.",
    "chunking-settings": "Gukata inyandiko byashyizweho neza.",
    "llm-saved": "Preferences za LLM zabitswe neza.",
    "multiuser-enabled": "Imikorere ya multi-user yashyizweho neza.",
    "publicuser-enabled": "Imikorere ya public-user yashyizweho neza.",
    "publicuser-disabled": "Imikorere ya public-user yavanyweho neza.",
    "page-refresh":
      "Urupapuro rwawe ruzashyirwa mu buryo bushya mu masegonda make.",
    "transcription-saved": "Preferences za transcription zabitswe neza.",
    "vector-saved": "Preferences za vector database zabitswe neza.",
    "workspace-not-deleted": "Workspace ntiyashoboye gusibwa!",
    "maximum-messages": "Ubutumwa bwa 4 byemewe.",
    "users-updated": "Abakoresha bashyizweho neza.",
    "vectordb-not-reset": "Workspace vector database ntiyashoboye gusubizwamo!",
    "vectordb-reset": "Workspace vector database yarasubiwemo!",
    "linked-workspaces-updated": "Ahantu habigenewe havuguruwe neza.",
  },

  "llm-selection-privacy": {
    openai: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri OpenAI",
      ],
    },
    azure: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zihuza ntabwo zigaragara kuri OpenAI cyangwa Microsoft",
      ],
    },
    anthropic: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri Anthropic",
      ],
    },
    gemini: {
      description: [
        "Ibiganirizwa byawe birahinduwe ibimenyetso no gukoresha mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri Google",
      ],
    },
    lmstudio: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa ku murongo ukoresha LMStudio",
      ],
    },
    localai: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa ku murongo ukoresha LocalAI",
      ],
    },
    ollama: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa ku mashini ikoreshwa na Ollama",
      ],
    },
    native: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa kuri iyi instance ya platform",
      ],
    },
    togetherai: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri TogetherAI",
      ],
    },
    mistral: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri Mistral",
      ],
    },
    huggingface: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza byoherezwa kuri endpoint ya HuggingFace",
      ],
    },
    perplexity: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri Perplexity AI",
      ],
    },
    openrouter: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri OpenRouter",
      ],
    },
    groq: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri Groq",
      ],
    },
    koboldcpp: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa ku murongo ukoresha KoboldCPP",
      ],
    },
    textgenwebui: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa ku murongo ukoresha Oobabooga Text Generation Web UI",
      ],
    },
    "generic-openai": {
      description: [
        "Données partagées selon les conditions de service applicables avec votre fournisseur de point de terminaison générique.",
      ],
    },
    cohere: {
      description: [
        "Les données sont partagées conformément aux conditions de service de cohere.com et aux lois sur la confidentialité de votre localité.",
      ],
    },
    litellm: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa ku murongo ukoresha LiteLLM",
      ],
    },
  },

  "vector-db-privacy": {
    chroma: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa ku instance yawe Chroma",
        "Kugera kuri instance yawe biragenzurwa na wewe",
      ],
    },
    pinecone: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa ku mashini za Pinecone",
        "Kugera ku makuru yawe biragenzurwa na Pinecone",
      ],
    },
    qdrant: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa ku instance yawe Qdrant (cloud cyangwa auto-hébergée)",
      ],
    },
    weaviate: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa ku instance yawe Weaviate (cloud cyangwa auto-hébergée)",
      ],
    },
    milvus: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa ku instance yawe Milvus (cloud cyangwa auto-hébergée)",
      ],
    },
    zilliz: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa ku cluster yawe ya Zilliz.",
      ],
    },
    astra: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa kuri base de données cloud AstraDB.",
      ],
    },
    lancedb: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa mu buryo bwihariye kuri iyi instance ya platform",
      ],
    },
  },

  "embedding-engine-privacy": {
    native: {
      description: [
        "Teksto y'inyandiko zawe ikozwe mu buryo bwihariye kuri iyi instance ya platform",
      ],
    },
    openai: {
      description: [
        "Teksto y'inyandiko zawe yoherezwa ku mashini za OpenAI",
        "Ibyo wanditse ntabwo bikoreshwa mu kwigisha",
      ],
    },
    azure: {
      description: [
        "Teksto y'inyandiko zawe yoherezwa kuri serivisi ya Microsoft Azure",
        "Ibyo wanditse ntabwo bikoreshwa mu kwigisha",
      ],
    },
    localai: {
      description: [
        "Teksto y'inyandiko zawe ikozwe mu buryo bwihariye ku murongo ukoresha LocalAI",
      ],
    },
    ollama: {
      description: [
        "Teksto y'inyandiko zawe ikozwe mu buryo bwihariye ku murongo ukoresha Ollama",
      ],
    },
    lmstudio: {
      description: [
        "Teksto y'inyandiko zawe ikozwe mu buryo bwihariye ku murongo ukoresha LMStudio",
      ],
    },
    cohere: {
      description: [
        "Données partagées conformément aux conditions de service de cohere.com et aux lois sur la confidentialité de votre localité.",
      ],
    },
    voyageai: {
      description: [
        "Les données envoyées aux serveurs Voyage AI sont partagées conformément aux conditions de service de voyageai.com.",
      ],
    },
  },

  "prompt-validate": {
    edit: "Hindura",
    response: "Igisubizo",
    prompt: "Gusaba",
    regenerate: "Kongera gukora igisubizo",
    good: "Igisubizo cyiza",
    bad: "Igisubizo kibi",
    copy: "Gukoporora",
    more: "Ibindi bikorwa",
    fork: "Koporora",
    delete: "Gusiba",
    cancel: "Guhagarika",
    save: "Bika & Ohereza",
  },

  citations: {
    show: "Erekana Ibisubizo",
    hide: "Hisha Ibisubizo",
    chunk: "Ibice by'Ibisubizo",
    pdr: "Inyandiko y'Ishakiro Yabonetse",
    "pdr-h": "Kwibanda ku Nyandiko isoko",
    referenced: "Byavuzwe",
    times: "incuro.",
    citation: "Ibisubizo",
    match: "bahuye",
    download:
      "Iki gikoresho ntigifasha kureba PDFs. Nyamuneka mukure PDF kugirango mubashe kuyireba :",
    "download-btn": "Kuramo PDF",
  },

  "document-drafting": {
    title: "Gutunganya Inyandiko",
    description: "Genzura uburyo bwo gutunganya inyandiko.",
    configuration: "Gushiraho",
    "drafting-model": "Icyifuzo cya LLM cyo Gutunganya Inyandiko",
    enabled: "Gutunganya Inyandiko byarakozwe",
    disabled: "Gutunganya Inyandiko birahagaritswe",
    "enabled-toast": "Gutunganya Inyandiko byarakozwe",
    "disabled-toast": "Gutunganya Inyandiko birahagaritswe",
    "desc-settings":
      "Umuyobozi ashobora guhindura uburyo bwo gutunganya inyandiko ku bakoresha bose.",
    "drafting-llm": "Icyifuzo cya LLM cyo Gutunganya Inyandiko",
    saving: "Birimo gukorwa...",
    save: "Bika impinduka",
    "chat-settings": "Igenamiterere rya Chat",
    "drafting-chat-settings": "Igenamiterere rya Chat ryo Kwandika Amadosiye",
    "chat-settings-desc":
      "Genzura imyitwarire y'igikoresho cya chat mu gihe cyo kwandika amadosiye.",
    "drafting-prompt": "Gusaba sisitemu yo gukora inyandiko",
    "drafting-prompt-desc":
      "Uburyo sisitemu ikoreshwa mu gukora inyandiko butandukanye n’uburyo bw’ikibazo n’igisubizo cya sisitemu y’amategeko. Gusaba sisitemu yo gukora inyandiko bigena uburyo n'amabwiriza bigamije gufasha AI gutanga igisubizo. Ugomba gutanga igisubizo cyatekerejwe neza kugirango AI ishobore gutanga igisubizo gikwiye kandi cyizewe.",
  },

  modale: {
    document: {
      title: "Inyandiko Zanjye",
      document: "Inyandiko",
      search: "Shakisha inyandiko",
      folder: "Idosiye Nshya",
      name: "Izina",
      empty: "Nta Nyandiko",
      "move-workspace": "Imura mu Murimo",
      "doc-processor": "Gutunganya Inyandiko Ntiboneka",
      "processor-offline":
        "Ntidushobora gushyira inyandiko zanyu ubu kuko gutunganya inyandiko byafunze. Nyamuneka gerageza nyuma.",
      "drag-drop": "Kanda kugirango ushyireho cyangwa ukuremo",
      "supported-files":
        "ishyigikira inyandiko, csv, impapuro z'ikubitiro, amajwi, n'ibindi byinshi!",
      "submit-link": "cyangwa shyiramo umuyoboro",
      fetch: "Kuramo urubuga",
      fetching: "Kuramo...",
      "file-desc":
        "Izi nyandiko zizashyirwa ku gutunganya inyandiko bikorwa kuri iyi sisitemu. Izi nyandiko ntizoherezwa cyangwa zigasangirwa n'undi muntu.",
      cost: "*Ikiguzi kimwe cy'ibihangano",
      "save-embed": "Bika kandi Ushyireho",
      "loading-message": "Ibi bishobora gufata igihe kinini ku nyandiko nini",
      "failed-uploads": "Ntibishobotse kohereza izi mbuga zikurikira",
    },
    connectors: {
      title: "Connecteurs de Données",
      search: "Shakisha Connecteurs de Données",
      empty: "Nta Connecteurs de Données babonetse.",
    },
  },

  dataConnectors: {
    github: {
      name: "Ububiko GitHub",
      description:
        "Injiza ububiko bwa GitHub rusange cyangwa bwihariye mu kanda rimwe gusa.",
      url: "URL y'Ububiko GitHub",
      "collect-url": "URL y'ububiko GitHub ushaka gukusanya.",
      "access-token": "Ikarita y'Ububiko GitHub",
      optional: "bidakenewe",
      "rate-limiting":
        "Ikarita y'Ububiko kugirango wirinde igipimo cyo gufunga.",
      "desc-picker":
        "Nimara kurangira, amadosiye yose azaboneka kugirango ashyirwe mu bibanza by'akazi muri mudasobwa y'amadosiye.",
      branch: "Ishami",
      "branch-desc": "Ishami ushaka gukusanya amadosiye.",
      "branch-loading": "-- gupakira amashami aboneka --",
      "desc-start": "Utuzuza",
      "desc-token": "Ikarita y'Ububiko GitHub",
      "desc-connector": "uyu mukozi w'amakuru azabasha gukusanya gusa",
      "desc-level": "amadosiye yo hejuru",
      "desc-end": "y'ububiko kubera igipimo cyo gufunga API rusange ya GitHub.",
      "personal-token":
        "Fungura ikarita y'ububiko y'umuntu ku giti cye hamwe na konte ya GitHub hano.",
      without: "Utari kumwe n'akarita y'ububiko y'umuntu ku giti cye",
      "personal-token-access": "Ikarita y'Ububiko y'Umuntu",
      "desc-api":
        ", API ya GitHub ishobora gufunga umubare w'amadosiye ashobora gukusanywa kubera igipimo cyo gufunga. Ushobora",
      "temp-token": "gukora ikarita y'ububiko y'agateganyo",
      "avoid-issue": "kugirango wirinde iki kibazo.",
      submit: "Ohereza",
      "collecting-files": "Gukusanya amadosiye...",
    },
    "youtube-transcript": {
      name: "Transcription ya YouTube",
      description:
        "Injiza transcription y'ikiganiro cyose cya YouTube uhereye ku murongo.",
      url: "URL y'Ikiganiro cya YouTube",
      "url-video": "URL y'ikiganiro cya YouTube ushaka gutandukanya.",
      collect: "Kusanya transcription",
      collecting: "Gukusanya transcription...",
      "desc-end":
        "Nimara kurangira, transcription izaboneka kugirango ishyirwe mu bibanza by'akazi muri mudasobwa y'amadosiye.",
    },
    "website-depth": {
      name: "Ububiko bwinshi bwa Link",
      description:
        "Kusanya urubuga n'uburinganire bwayo kugeza ku rugero runaka.",
      url: "URL y'Urubuga",
      "url-scrape": "URL y'urubuga ushaka gukusanya.",
      depth: "Ubujyakuzimu",
      "child-links":
        "Uyu ni umubare w'uburinganire bugomba gukurikizwa kuva kuri URL y'umwimerere.",
      "max-links": "Links z'Ububiko",
      "links-scrape": "Umubare ntarengwa w'uburinganire bugomba gukusanywa.",
      scraping: "Gukusanya urubuga...",
      submit: "Ohereza",
      "desc-scrap":
        "Nimara kurangira, amapaji yose yakusanyijwe azaboneka kugirango ashyirwe mu bibanza by'akazi muri mudasobwa y'amadosiye.",
    },
    confluence: {
      name: "Confluence",
      description: "Injiza urupapuro rwose rwa Confluence mu kanda rimwe gusa.",
      url: "URL y'Urupapuro rwa Confluence",
      "url-page": "URL y'urupapuro mu gace ka Confluence.",
      username: "Izina ry'Umukoresha Confluence",
      "own-username": "Izina ryawe ry'umukoresha Confluence.",
      token: "Ikarita y'Ububiko Confluence",
      "desc-start":
        "Ukeneye gutanga ikarita y'ububiko kugirango ukore authentification. Ushobora gukora ikarita y'ububiko",
      here: "hano",
      access: "Ikarita y'ububiko kugirango ukore authentification.",
      collecting: "Gukusanya amapaji...",
      submit: "Ohereza",
      "desc-end":
        "Nimara kurangira, amapaji yose azaboneka kugirango ashyirwe mu bibanza by'akazi.",
    },
  },

  module: {
    "legal-qa": "Ibibazo n'Ibisubizo ku Mategeko",
    "document-drafting": "Guteza Imbere Amakuru",
  },

  "fine-tune": {
    title: "Ufite amakuru ahagije yo gukosora byimbitse!",
    link: "kanda hano kugira ngo umenye byinshi",
    dismiss: "fungura",
  },

  mobile: {
    disclaimer:
      "INTEGUZA: Kugira ngo ukoreshe neza kandi ubone ibyiza byose, koresha mudasobwa kugira ngo winjire muri iyi porogaramu.",
  },

  "default-settings": {
    title: "Igenamiterere Rya Default ku Mibazo N'ibisubizo by'Amategeko",
    "default-desc":
      "Genzura imyitwarire isanzwe y'ibyumba by'akazi ku Mibazo N'ibisubizo by'Amategeko",
    prompt: "Guhamagara Sisitemu y'ibibazo by'Amategeko",
    "prompt-desc":
      "Guhamagara bisanzwe bizakoreshwa mu Mibazo N'ibisubizo by'Amategeko. Tangaza aho bisohokera hamwe n'amabwiriza kugira ngo AI ikore igisubizo. Ugomba gutanga guhamagara gushyizwe mu murongo neza kugira ngo AI ibashe gutanga igisubizo gikwiriye kandi cyizewe.",
    "toast-success": "Guhamagara bisanzwe bwa sisitemu byavuguruwe",
    "toast-fail": "Ntibyashobotse kuvugurura guhamagara bwa sisitemu",
    snippets: {
      title: "Umubare ntarengwa w'ubusobanuro bw'inyandiko",
      description:
        "Iyi myanya igenzura umubare ntarengwa w'ubusobanuro bw'inyandiko bizahabwa LLM kuri buri kiganiro cyangwa ikibazo.",
      recommend: "Birakwiye: 10",
    },
  },

  "confirm-message": {
    "delete-doc":
      "Urashaka koko gusiba izi nyandiko n'ububiko?\nIbi bizakuraho inyandiko mu buryo bwikora kandi bizikure mu bice byose by'ububiko bihari.\nIbi ntibishobora gusubirwaho.",
  },

  "pdr-settings": {
    title: "Igenamiterere ya PDR",
    description: "Gena uburyo bwo kugenzura imiterere ya token ya PDR",
    "desc-end":
      "Ugomba guhindura iri igenamiterere gusa niba usobanukiwe uburyo PDR ikora.",
    "pdr-token-limit": "Umubare Ntarengwa wa PDR Tokens",
    "pdr-token-limit-desc": "Umubare ntarengwa wa tokens ukoreshwa na PDR.",
    "input-prompt-token-limit": "Umubare Ntarengwa wa Tokens mu Guhamagara",
    "input-prompt-token-limit-desc":
      "Umubare ntarengwa wa tokens uzakoreshwa mu guhamagara.",
    "response-token-limit": "Umubare Ntarengwa wa Tokens mu Gusubiza",
    "response-token-limit-desc":
      "Umubare ntarengwa wa tokens uzakoreshwa mu gusubiza.",
    "toast-success": "Igenamiterere rya PDR ryaravuguruwe",
    "toast-fail": "Ntibyashobotse kuvugurura igenamiterere rya PDR",
  },
};

export default TRANSLATIONS;
